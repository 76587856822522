// i18nOwl-ignore [after_or_equal, before_or_equal, contains, ends_with, eq, gt, gte, in_next_days, in_past_days, is, is_today]
// i18nOwl-ignore [is_in, is_in_the_future, is_in_the_past, is_member, is_not, is_not_member, lt, lte, ne, starts_with, is_true, is_false]
// i18nOwl-ignore [is_not_in, not_starts_with, not_ends_with, not_contains, is_set, is_not_set]
import {
  CohortMembershipCriterionOperatorSchema,
  UserEventCriterionFilterOperatorSchema,
  UserEventCriterionOperatorSchema,
  UserPropertyCriterionOperatorSchema,
} from '@cohort/shared/schema/common/rules/operators';
import {useTranslation} from 'react-i18next';

const operatorsByType = {
  'user-property': UserPropertyCriterionOperatorSchema.enum,
  'user-event': UserEventCriterionOperatorSchema.enum,
  'user-event-filter': UserEventCriterionFilterOperatorSchema.enum,
  'cohort-membership': CohortMembershipCriterionOperatorSchema.enum,
} as const;
type CriteriaType = keyof typeof operatorsByType;

export function useOperators(
  criteria: CriteriaType
): {value: string; type: string; label: string}[] {
  const {t} = useTranslation('hooks', {
    keyPrefix: 'rules.operators',
  });
  const operators = operatorsByType[criteria];

  return Object.entries(operators).map(([value]) => {
    const operatorName = value.split('.');

    return {
      value,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      type: operatorName[0]!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      label: t(operatorName.length === 1 ? operatorName[0]! : operatorName[1]!),
    };
  });
}
