import type {CreateAirdropDataADto} from '@cohort/admin-schemas/airdrops';
import type {CohortADto} from '@cohort/admin-schemas/cohort';
import Button from '@cohort/merchants/components/buttons/Button';
import SelectCohort from '@cohort/merchants/components/cohorts/SelectCohort';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {PerkVisual} from '@cohort/merchants/components/PerkVisual';
import {standaloneAirdropKeys} from '@cohort/merchants/hooks/api/Airdrops';
import {perkAccessesKeys} from '@cohort/merchants/hooks/api/PerkAccesses';
import {perksKeys} from '@cohort/merchants/hooks/api/Perks';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {createStandaloneAirdrop} from '@cohort/merchants/lib/api/Airdrops';
import {defaultNetworkErrorMessage} from '@cohort/shared/models';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import React, {useState} from 'react';
import type {UseFormRegister} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

type PerkAirdropModalProps = {
  onClose: () => void;
};

type PerkAirdropFormProps = {
  register: UseFormRegister<PerkAirdropValues>;
  setError: (error: string | null | undefined) => void;
};

const PerkAirdropSchema = z.object({
  cohortId: z.string().uuid(),
});

type PerkAirdropValues = z.infer<typeof PerkAirdropSchema>;

const PerkAirdropForm: React.FC<PerkAirdropFormProps> = ({register, setError}) => {
  const perk = useCurrentPerk();
  const {t} = useTranslation('components', {
    keyPrefix: 'perks.perkAirdropModal',
  });

  return (
    <div className="mb-4 mt-8 flex flex-col gap-4">
      <section>
        <label className="mb-1 block text-sm font-medium text-slate-700">{t('perkLabel')}</label>
        <div className="flex items-center gap-2 rounded-md border bg-slate-50 p-2">
          <PerkVisual perk={perk} className="h-8" />
          <p>{perk.internalName}</p>
        </div>
      </section>
      <SelectCohort
        register={register}
        onChange={(cohort: CohortADto) => {
          if (cohort.usersCount === 0) {
            setError(t('noUsersInCohort'));
          } else {
            setError(null);
          }
        }}
      />
    </div>
  );
};

const PerkAirdropModal: React.FC<PerkAirdropModalProps> = ({onClose}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const perk = useCurrentPerk();
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null | undefined>(undefined);

  const {t} = useTranslation('components', {
    keyPrefix: 'perks.perkAirdropModal',
  });

  const {isLoading, mutate: startAirdrop} = useCohortMutation({
    mutationFn: async (data: CreateAirdropDataADto) => createStandaloneAirdrop(merchantId, data),
    notifySuccessMessage: t('notificationAirdropSuccess'),
    onError: (err: unknown) => {
      let errorMessage = defaultNetworkErrorMessage;
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      notify('error', errorMessage);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(standaloneAirdropKeys.airdrops);
      await queryClient.invalidateQueries(perkAccessesKeys.perkAccesses);
      await queryClient.invalidateQueries(perksKeys.perks);
      onClose();
    },
  });

  const {register, getValues} = useForm<PerkAirdropValues>({
    resolver: zodResolver(PerkAirdropSchema),
  });

  const onSubmit = (): void => {
    const data = getValues();
    startAirdrop({perkIds: [perk.id], digitalAssetCollectionIds: [], cohortId: data.cohortId});
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent data-testid="campaign-airdrop-modal">
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold">{t('modalTitle')}</DialogTitle>
          <DialogDescription className="m-0 text-sm">{t('modalDescription')}</DialogDescription>
        </DialogHeader>
        <DialogBody className="mt-0 py-0">
          <PerkAirdropForm register={register} setError={setError} />
        </DialogBody>
        <DialogFooter className="flex flex-col gap-4 bg-transparent">
          {error && <HighlightText type="error" text={error} />}
          <div className="flex justify-between">
            <Button key="close-duplicate-modal" variant="secondary" onClick={onClose}>
              {t('cancelBtn')}
            </Button>
            <Button onClick={onSubmit} loading={isLoading} disabled={error !== null}>
              {t('sendBtn')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PerkAirdropModal;
