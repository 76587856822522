import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {LocalizedStringSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CohortScanQrCodeTriggerConfigSchema = z.object({
  successImageFileKey: z.string().nullable(),
  successMessage: LocalizedStringSchema,
});
export type CohortScanQrCodeTriggerConfig = z.infer<typeof CohortScanQrCodeTriggerConfigSchema>;

export const CohortScanQrCodeVerificationAttemptDataSchema = z.object({});
export type CohortScanQrCodeVerificationAttemptData = z.infer<
  typeof CohortScanQrCodeVerificationAttemptDataSchema
>;
export const CohortScanQrCodeVerificationAttemptInputSchema = z.object({
  campaignId: z.string(),
  stepId: z.string(),
});
export type CohortScanQrCodeVerificationAttemptInput = z.infer<
  typeof CohortScanQrCodeVerificationAttemptInputSchema
>;

export type CohortScanQrCodeTriggerStruct = TriggerIntegrationStruct<
  'cohort.scan-qr-code',
  CohortScanQrCodeTriggerConfig,
  CohortScanQrCodeVerificationAttemptData,
  CohortScanQrCodeVerificationAttemptInput
>;

export const CohortScanQrCodeTriggerIntegrationSpec: TriggerIntegrationSpec<CohortScanQrCodeTriggerStruct> =
  {
    id: 'cohort.scan-qr-code' as const,
    configurableMaxAttempts: false,
    configSchema: CohortScanQrCodeTriggerConfigSchema,
    verificationAttemptDataSchema: CohortScanQrCodeVerificationAttemptDataSchema,
    verificationAttemptInputSchema: CohortScanQrCodeVerificationAttemptInputSchema,
    requiresMerchantConnection: false,
  };
