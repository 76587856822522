import Spinner from '@cohort/shared-frontend/components/Spinner';

const EmbedSkeleton: React.FC<{
  width: string | number;
  height?: string | number;
  children?: React.ReactNode;
}> = ({width, height = width, children}) => (
  <div
    className="flex animate-pulse items-center justify-center gap-2 border bg-[--xps-card-background-color]"
    style={{width, height}}
  >
    <Spinner size={24} />
    {children}
  </div>
);

export default EmbedSkeleton;
