import ImportUsersFieldSelect from '@cohort/merchants/apps/salesforce/sync/ImportUsersFieldSelect';
import type {SalesforceUserObject} from '@cohort/merchants/apps/salesforce/sync/utils';
import {
  buildDefaultUserPropertyColumn,
  getSalesforceObjectByName,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import Button from '@cohort/merchants/components/buttons/Button';
import FieldLabel from '@cohort/merchants/components/form/FieldLabel';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import {PlusCircle} from '@phosphor-icons/react';
import {useEffect} from 'react';
import {useFieldArray} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';

type ImportUsersFieldsSelectProps = {
  salesforceUserObjects: SalesforceUserObject[];
};
const ImportUsersFields: React.FC<ImportUsersFieldsSelectProps> = ({salesforceUserObjects}) => {
  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.importUsersFields',
  });

  const {control, watch} = useCohortForm<Extract<SyncConfigFormValues, {appId: 'salesforce'}>>();

  const isImportEnabled = watch('userImportEnabled');
  const userSyncConfig = watch('userSyncConfig');

  const selectedObject = getSalesforceObjectByName(
    salesforceUserObjects,
    userSyncConfig?.salesforceObjectName ?? ''
  );

  const {append: addUserPropertyColumn, replace: replaceUserPropertyColumns} = useFieldArray({
    control,
    name: 'userSyncConfig.userPropertyColumns',
  });

  useEffect(() => {
    if (userSyncConfig?.userPropertyColumns === undefined) {
      return;
    }

    if (userSyncConfig.userPropertyColumns.length === 0) {
      replaceUserPropertyColumns([buildDefaultUserPropertyColumn()]);
    }
  }, [userSyncConfig?.userPropertyColumns, replaceUserPropertyColumns]);

  const handleAddField = (): void => {
    addUserPropertyColumn(buildDefaultUserPropertyColumn());
  };

  return (
    <div className="flex flex-col gap-2">
      <FieldLabel label={t('labelUserProperties')} optional />
      <div className="flex w-full">
        <div className="flex w-[344px] flex-row items-center space-x-1.5">
          <SvgAppIcon name="salesforce" width={24} height={24} />
          <p className="text-sm font-normal text-slate-700">
            <Trans
              i18nKey="sync.importUsersFields.labelSalesforceField"
              ns="app-salesforce"
              components={{bold: <span className="font-medium text-slate-700" />}}
            />
          </p>
        </div>
        <div className="flex flex-row items-center space-x-1.5">
          <SvgAppIcon name="cohort" width={24} height={24} />
          <p className="text-sm font-normal text-slate-700">
            <Trans
              i18nKey="sync.importUsersFields.labelCohortUserProperty"
              ns="app-salesforce"
              components={{bold: <span className="font-medium text-slate-700" />}}
            />
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {userSyncConfig?.userPropertyColumns !== undefined &&
          userSyncConfig.userPropertyColumns.map((column, index) => {
            const selectedField =
              selectedObject?.fields.find(field => field.name === column.columnName) ?? null;

            return (
              <ImportUsersFieldSelect
                key={`field-select-${index}`}
                index={index}
                salesforceUserObjects={salesforceUserObjects}
                selectedField={selectedField}
              />
            );
          })}
      </div>
      <div className="mt-2">
        <Button
          variant="secondary"
          onClick={handleAddField}
          disabled={!isImportEnabled || selectedObject === undefined}
        >
          <PlusCircle size={20} className="mr-2" />
          {t('addFieldButton')}
        </Button>
      </div>
    </div>
  );
};

export default ImportUsersFields;
