// i18nOwl-ignore [app-instagram.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import {makeOauthEditConnectionComponent} from '@cohort/merchants/apps/appUtils';
import {SetupConnectionAccount} from '@cohort/merchants/apps/instagram/connector/SetupConnectionAccount';
import translationEn from '@cohort/merchants/apps/instagram/locales/en.json';
import translationFr from '@cohort/merchants/apps/instagram/locales/fr.json';
import {InstagramCloseFriendsListPerkIntegration} from '@cohort/merchants/apps/instagram/perkIntegrations/closeFriendsList/PerkIntegration';
import {InstagramCommentMediaTriggerIntegration} from '@cohort/merchants/apps/instagram/triggerIntegrations/commentMedia/TriggerIntegration';
import {InstagramFollowTriggerIntegration} from '@cohort/merchants/apps/instagram/triggerIntegrations/follow/TriggerIntegration';
import {InstagramLikeMediaTriggerIntegration} from '@cohort/merchants/apps/instagram/triggerIntegrations/likeMedia/TriggerIntegration';
import {InstagramTagInPostTriggerIntegration} from '@cohort/merchants/apps/instagram/triggerIntegrations/tagInPost/TriggerIntegration';
import type {InstagramAppStruct} from '@cohort/shared/apps/instagram';
import {InstagramAppSpec} from '@cohort/shared/apps/instagram';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const InstagramApp: App<InstagramAppStruct> = {
  spec: InstagramAppSpec,
  ConnectionEditComponent: makeOauthEditConnectionComponent('instagram', {
    postConnectionConfigComponent: SetupConnectionAccount,
  }),
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="instagram" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [InstagramCloseFriendsListPerkIntegration],
  triggerIntegrations: [
    InstagramFollowTriggerIntegration,
    InstagramCommentMediaTriggerIntegration,
    InstagramLikeMediaTriggerIntegration,
    InstagramTagInPostTriggerIntegration,
  ] as Array<TriggerIntegration>,
  userEvents: [],
};

export default InstagramApp;
