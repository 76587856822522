import {ContentCategoryASchema} from '@cohort/admin-schemas/contentCategory';
import {MediaKindSchema} from '@cohort/shared/apps';
import {asArray, DatetimeSchema} from '@cohort/shared/schema/common';
import {
  ContentAudienceSchema,
  ContentDescriptionSchema,
  ContentsOrderBySchema,
  ContentStatusSchema,
  ContentTitleSchema,
} from '@cohort/shared/schema/common/content';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {z} from 'zod';

export const CreateContentASchema = z.object({
  internalName: z.string(),
});
export type CreateContentADto = z.infer<typeof CreateContentASchema>;

export const PatchContentASchema = z
  .object({
    internalName: z.string(),
    cohortId: z.string().uuid().nullable(),
    title: ContentTitleSchema,
    description: ContentDescriptionSchema,
    status: ContentStatusSchema,
    media: z.object({
      externalId: z.string().nullable(),
      config: z.record(z.unknown()),
      kind: MediaKindSchema,
    }),
    isHighlighted: z.boolean(),
    categoryIds: z.array(z.string().uuid()),
    audience: ContentAudienceSchema,
  })
  .partial();
export type PatchContentADto = z.infer<typeof PatchContentASchema>;

const ContentCategoryAssociationASchema = z.object({
  contentId: z.string().uuid(),
  contentCategoryId: z.string().uuid(),
  createdAt: DatetimeSchema,
});

export type ContentCategoryAssociationADto = z.infer<typeof ContentCategoryAssociationASchema>;

export const ContentASchema = z.object({
  id: z.string().uuid(),
  cohortId: z.string().uuid().nullable(),
  internalName: z.string(),
  merchantId: z.string().uuid(),
  mode: z.string(),
  title: ContentTitleSchema,
  description: ContentDescriptionSchema,
  status: ContentStatusSchema,
  media: z.object({
    kind: MediaKindSchema.nullable(),
    externalId: z.string().nullable(),
    config: z.any(),
  }),
  isHighlighted: z.boolean(),
  createdAt: DatetimeSchema,
  updatedAt: DatetimeSchema,
  categories: z.array(ContentCategoryASchema),
  audience: ContentAudienceSchema.nullable(),
});
export type ContentADto = z.infer<typeof ContentASchema>;

export const ContentsResponseASchema = z.array(ContentASchema);
export type ContentsResponseADto = z.infer<typeof ContentsResponseASchema>;

export const GetContentsParamsASchema = PaginationParamsSchema.extend({
  orderBy: ContentsOrderBySchema,
  status: z.preprocess(asArray, z.array(ContentStatusSchema).optional()),
  mediaKind: z.preprocess(asArray, z.array(MediaKindSchema).optional()),
  audience: z.preprocess(asArray, z.array(ContentAudienceSchema).optional()),
  search: z.string().optional(),
});
export type GetContentsParamsADto = z.infer<typeof GetContentsParamsASchema>;
