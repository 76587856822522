import type {
  FacebookLikePageTriggerConfig,
  FacebookLikePageVerificationAttemptData,
} from '@cohort/shared/apps/facebook/triggers/likePage';
import {
  FacebookLikePageTriggerConfigSchema,
  FacebookLikePageVerificationAttemptDataSchema,
} from '@cohort/shared/apps/facebook/triggers/likePage';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export type FacebookLikePageUnverifiedTriggerStruct = TriggerIntegrationStruct<
  'facebook.like-page-unverified',
  FacebookLikePageTriggerConfig,
  FacebookLikePageVerificationAttemptData
>;
export const FacebookLikePageUnverifiedTriggerSpec: TriggerIntegrationSpec<FacebookLikePageUnverifiedTriggerStruct> =
  {
    id: 'facebook.like-page-unverified' as const,
    configurableMaxAttempts: false,
    configSchema: FacebookLikePageTriggerConfigSchema,
    verificationAttemptDataSchema: FacebookLikePageVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
