import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import type {CohortScanQrCodeVerificationAttemptData} from '@cohort/shared/apps/cohort/triggers/scanQrCode';

const CohortScanQrCodeTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: CohortScanQrCodeVerificationAttemptData;
  completionSuccess: boolean;
}> = () => (
  <StepCompletionCard
    translationConfig={{
      // i18nOwl-ignore [app-cohort.triggerIntegrations.scan-qr-code.stepCompletionTitleComponent.success]
      key: 'triggerIntegrations.scan-qr-code.stepCompletionTitleComponent.success',
      ns: 'app-cohort',
      value: null,
    }}
  />
);

export default CohortScanQrCodeTriggerIntegrationStepCompletionTitleComponent;
