import NetworkedSelect from '@cohort/merchants/components/form/select/NetworkedSelect';
import {useResources} from '@cohort/merchants/hooks/api/Resources';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {ResourceType} from '@cohort/shared/apps';
import {useState} from 'react';
import type {Control, FieldValues, Path} from 'react-hook-form';
import {useController} from 'react-hook-form';

type ResourceSelectInputProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  resourceType: ResourceType;
  merchantConnectionId?: string;
};

const ResourceSelectInput = <T extends FieldValues>({
  name,
  control,
  resourceType,
  merchantConnectionId,
}: ResourceSelectInputProps<T>): JSX.Element => {
  const merchant = useCurrentMerchant();
  const {field, fieldState} = useController({
    name,
    control,
  });
  const [search, setSearch] = useState<string | undefined>(undefined);
  const {data: resources, isLoading} = useResources(merchant.id, resourceType, {
    search,
    merchantConnectionId,
  });

  const options =
    resources?.map(resource => ({
      value: resource.id,
      label: resource.name,
    })) ?? [];

  return (
    <NetworkedSelect
      name={name}
      error={fieldState.error?.message}
      options={options}
      onInputChange={setSearch}
      isLoading={isLoading}
      onChange={val => field.onChange(val?.value)}
      value={options.find(option => option.value === field.value) ?? null}
    />
  );
};

export default ResourceSelectInput;
