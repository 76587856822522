import type {AdditionalSelectPickerProps} from '@cohort/merchants/components/form/select/SelectPicker';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import {useRef} from 'react';
import type {GroupBase, Props} from 'react-select';

const NetworkedSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  onInputChange,
  ...props
}: Props<Option, IsMulti, Group> &
  AdditionalSelectPickerProps<Option, IsMulti, Group>): JSX.Element => {
  const timerId = useRef<NodeJS.Timeout>();

  return (
    <SelectPicker
      onInputChange={val => {
        if (timerId.current) {
          clearTimeout(timerId.current);
        }
        timerId.current = setTimeout(() => onInputChange?.(val), 200);
      }}
      filterOption={null}
      {...props}
    />
  );
};

export default NetworkedSelect;
