import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import {defaultStyle, getTheme} from '@cohort/merchants/lib/form/select';
import {useTranslation} from 'react-i18next';
import type {GroupBase, Props, SelectInstance, StylesConfig} from 'react-select';
import Select from 'react-select';

export type SelectOption = {
  value: string | boolean | number | Record<string, unknown>;
  label: string | JSX.Element;
  isDisabled?: boolean;
};

export type SelectDisplayMode = 'overflow' | 'expand';

export type AdditionalSelectPickerProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = WrappedField & {
  name: string;
  onInputChange?: (val: string) => void;
  error?: string;
  selectRef?: React.ForwardedRef<SelectInstance<Option, IsMulti, Group>>;
};

export const SelectPicker = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  error,
  menuPlacement = 'auto',
  selectRef,
  ...props
}: Props<Option, IsMulti, Group> &
  AdditionalSelectPickerProps<Option, IsMulti, Group>): JSX.Element => {
  const {t} = useTranslation('components', {keyPrefix: 'form.selectPicker'});

  return (
    <FieldWrapper
      label={props.label}
      labelPosition={props.labelPosition}
      name={name}
      description={props.description}
      error={error}
    >
      <Select
        id="react-select-picker"
        {...props}
        ref={selectRef}
        theme={getTheme}
        menuPortalTarget={document.body}
        menuPlacement={menuPlacement}
        styles={
          {
            ...defaultStyle,
            menuPortal: base => ({...base, zIndex: 9999}),
            menu: baseStyles => ({
              ...baseStyles,
              borderRadius: '6px',
            }),
          } as StylesConfig<Option, IsMulti, Group>
        }
        placeholder={props.placeholder ?? t('placeholder')}
        noOptionsMessage={() => t('noOptionsMessage')}
      />
    </FieldWrapper>
  );
};
