import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {FacebookMerchantConnectorStruct} from '@cohort/shared/apps/facebook/merchantConnector';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

type FacebookPageInfoConfigComponentProps = TriggerIntegrationConfigComponentProps & {
  isUnverified?: boolean;
};

const FacebookPageInfoConfigComponent: React.FC<FacebookPageInfoConfigComponentProps> = ({
  formReturn,
  connectionId,
  isUnverified = false,
}) => {
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('app-facebook');

  const {data: profile} = useConnectionProfile<FacebookMerchantConnectorStruct>(
    merchant.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    connectionId!,
    {enabled: !!connectionId}
  );

  const {setValue} = formReturn;

  useEffect(() => {
    if (!profile) {
      return;
    }

    setValue('triggerIntegrationConfig.pageId', profile.properties.id);
    setValue('triggerIntegrationConfig.pageName', profile.properties.username);
  }, [setValue, profile]);

  if (isUnverified) {
    return (
      <HighlightText
        type="info"
        text={t('triggers.facebookPageInfoConfigComponent.unverifiedInfo')}
      />
    );
  }

  return null;
};

export function getFacebookPageInfoConfigComponent({
  isUnverified = false,
}: {
  isUnverified?: boolean;
}) {
  return (props: TriggerIntegrationConfigComponentProps) => (
    <FacebookPageInfoConfigComponent {...props} isUnverified={isUnverified} />
  );
}
