import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';
import {forwardRef, useCallback, useState} from 'react';

type InsetElementProps = React.ComponentPropsWithoutRef<'div'> & {position: 'left' | 'right'};

const InsetElement = React.forwardRef<React.ElementRef<'div'>, InsetElementProps>(
  ({className, position, ...props}, ref) => (
    <div
      ref={ref}
      className={cn(
        'pointer-events-none absolute inset-y-0 mb-px ml-px mt-px flex items-center bg-slate-50 px-3 text-sm text-slate-500',
        position === 'left' && 'left-0 rounded-bl-md rounded-tl-md border-r',
        position === 'right' && 'right-0 rounded-br-md rounded-tr-md border-l',
        className
      )}
      {...props}
    />
  )
);

export type BaseInputProps = Omit<JSX.IntrinsicElements['input'], 'name' | 'ref'> &
  WrappedField & {
    error?: string;
    name: string;
    insetPrefix?: string | JSX.Element;
    insetSuffix?: string | JSX.Element;
  };

const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      insetPrefix,
      insetSuffix,
      label,
      description,
      labelPosition,
      name,
      optional,
      error,
      className,
      ...props
    },
    ref
  ) => {
    const [prefixWidth, setPrefixWidth] = useState(0);
    const [suffixWidth, setSuffixWidth] = useState(0);

    const prefixRef = useCallback((node: HTMLInputElement | null) => {
      if (node) {
        setPrefixWidth(node.offsetWidth);
      }
    }, []);

    const suffixRef = useCallback((node: HTMLInputElement | null) => {
      if (node) {
        setSuffixWidth(node.offsetWidth);
      }
    }, []);

    return (
      <FieldWrapper
        label={label}
        name={name}
        description={description}
        labelPosition={labelPosition}
        optional={optional}
        error={error}
      >
        <div
          className={cn('relative w-full', props.type === 'checkbox' && 'flex w-fit items-center')}
        >
          {insetPrefix && (
            <InsetElement position="left" ref={prefixRef}>
              {insetPrefix}
            </InsetElement>
          )}
          <input
            className={cn(
              'rounded-md border border-border p-2 text-sm shadow-sm focus:border-primary focus:ring-primary',
              props.disabled && 'cursor-not-allowed bg-slate-100 text-slate-400',
              props.type !== 'checkbox' ? 'w-full' : 'text-primary',
              props.type === 'radio' && 'text-primary',
              className
            )}
            style={{
              paddingLeft: prefixWidth ? `${prefixWidth + 12}px` : undefined,
              paddingRight: suffixWidth ? `${suffixWidth + 12}px` : undefined,
            }}
            data-testid={name}
            name={name}
            {...props}
            ref={ref}
          />
          {insetSuffix && (
            <InsetElement position="right" ref={suffixRef}>
              {insetSuffix}
            </InsetElement>
          )}
        </div>
      </FieldWrapper>
    );
  }
);

export default BaseInput;
