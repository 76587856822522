import YoutubeVideoPlayer from '@cohort/components-xps/components/apps/youtube/YoutubeVideoPlayer';
import {Card} from '@cohort/components-xps/components/cards/Card';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import MediaContentInfos from '@cohort/components-xps/components/contents/MediaContentInfos';
import type {YoutubeVideoMediaStruct} from '@cohort/shared/apps/youtube/medias/video';

const MediaContentYoutubeVideo: React.FC<MediaContentProps<YoutubeVideoMediaStruct>> = ({
  config,
  description,
  title,
}) => {
  return (
    <Card className="space-y-4">
      {config?.videoId && <YoutubeVideoPlayer videoId={config.videoId} />}
      <MediaContentInfos title={title} description={description} />
    </Card>
  );
};

export default MediaContentYoutubeVideo;
