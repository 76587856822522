import type {ListResourceADto, ListResourceParamsADto} from '@cohort/admin-schemas/resources';
import {ListResourceASchema} from '@cohort/admin-schemas/resources';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ResourceType} from '@cohort/shared/apps';
import type {ResourceStruct} from '@cohort/shared/apps/resource';

export async function listResource<R extends ResourceStruct = ResourceStruct>(
  merchantId: string,
  resourceType: ResourceType,
  params?: ListResourceParamsADto
): Promise<ListResourceADto<R>> {
  return apiCall('GET', `/v1/merchants/${merchantId}/resources/${resourceType}`, {
    expect: HttpCodes.SUCCESS,
    parser: ListResourceASchema.parse,
    params,
  });
}
