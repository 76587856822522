import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortScanQrCodeTriggerIntegrationConfigComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/scanQrCode/ConfigComponent';
import CohortScanQrCodeTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/scanQrCode/StepCompletionTitleComponent';
import {fileValidatorOptional} from '@cohort/merchants/lib/form/validators';
import type {CohortScanQrCodeTriggerStruct} from '@cohort/shared/apps/cohort/triggers/scanQrCode';
import {CohortScanQrCodeTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/scanQrCode';
import {LocalizedStringSchema} from '@cohort/shared/schema/common';
import {isEmpty} from 'remeda';
import {z} from 'zod';

const CohortScanQrCodeConfigFormSchema = z.object({
  successImageFileKey: z.any().refine(fileValidatorOptional),
  successMessage: LocalizedStringSchema,
});
type CohortScanQrCodeConfigForm = z.infer<typeof CohortScanQrCodeConfigFormSchema>;

export const CohortScanQrCodeTriggerIntegration: TriggerIntegration<CohortScanQrCodeTriggerStruct> =
  {
    spec: CohortScanQrCodeTriggerIntegrationSpec,
    configComponent: CohortScanQrCodeTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: CohortScanQrCodeTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggerIntegrations.scan-qr-code.title', {ns: 'app-cohort'}),
    configAssets: [
      {
        name: 'successImageFileKey',
        type: 'qrCodeSuccessImage',
      },
    ],
    configForm: {
      schema: CohortScanQrCodeConfigFormSchema,
      toForm: config => config,
      fromForm: (formValues: CohortScanQrCodeConfigForm) => {
        const {successImageFileKey, ...rest} = formValues;

        return {
          ...rest,
          successImageFileKey: isEmpty(successImageFileKey) ? null : successImageFileKey,
        };
      },
    },
  };
