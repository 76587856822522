import type {BaseInputProps} from '@cohort/merchants/components/form/input/BaseInput';
import BaseInput from '@cohort/merchants/components/form/input/BaseInput';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import {updateLocalizedString} from '@cohort/merchants/lib/Utils';
import type {Language} from '@cohort/shared/schema/common';
import get from 'lodash/get';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';

type LocalizedInputProps<T extends FieldValues> = BaseInputProps &
  FormField<T> & {
    selectedLanguage: Language;
  };

export default function LocalizedInput<T extends FieldValues>({
  insetPrefix,
  insetSuffix,
  register,
  control,
  rules,
  selectedLanguage,
  ...props
}: LocalizedInputProps<T>): JSX.Element {
  const {field, fieldState} = useController({
    control,
    name: props.name,
  });

  const errorMessage =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    get(fieldState, `error.${selectedLanguage}.message`) ?? get(fieldState, `error.message`);

  return (
    <BaseInput
      insetPrefix={insetPrefix}
      insetSuffix={insetSuffix}
      error={errorMessage}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(updateLocalizedString(selectedLanguage, field.value, event.target.value));
      }}
      value={field.value?.[selectedLanguage] ?? ''}
      {...props}
      ref={field.ref}
    />
  );
}
