import type {ResourceStruct} from '@cohort/shared/apps/resource';
import {ResourcePropertiesSchema} from '@cohort/shared/apps/resource';
import {z} from 'zod';

export const ListResourceASchema = z.array(ResourcePropertiesSchema);
export type ListResourceADto<R extends ResourceStruct = ResourceStruct> = Array<R['Properties']>;

export const GetResourceByIdASchema = ResourcePropertiesSchema;
export type GetResourceByIdADto<R extends ResourceStruct = ResourceStruct> = R['Properties'];

export const ListResourceParamsASchema = z.object({
  search: z.string().optional(),
  merchantConnectionId: z.string().optional(),
});
export type ListResourceParamsADto = z.infer<typeof ListResourceParamsASchema>;
