import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {TwitterMerchantConnectorStruct} from '@cohort/shared/apps/twitter/merchantConnector';
import {TWITTER_URL} from '@cohort/shared-frontend/common/apps/twitter';
import {useEffect} from 'react';

const TwitterFollowTriggerConfigComponent: React.FC<TriggerIntegrationConfigComponentProps> = ({
  connectionId,
  formReturn,
}) => {
  const merchant = useCurrentMerchant();
  const {data: profile} = useConnectionProfile<TwitterMerchantConnectorStruct>(
    merchant.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    connectionId!,
    {enabled: !!connectionId}
  );
  const {setValue} = formReturn;

  useEffect(() => {
    if (!profile) {
      return;
    }

    setValue(
      'triggerIntegrationConfig.profileUrl',
      `${TWITTER_URL}/${profile.properties.username}`
    );
  }, [setValue, profile]);

  return null;
};

export default TwitterFollowTriggerConfigComponent;
