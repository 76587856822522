import {FacebookCommentSchema} from '@cohort/shared/apps/facebook/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const FacebookCommentPostTriggerConfigSchema = z.object({
  postId: z.string(),
  postPermalink: z.string().url(),
});
type FacebookCommentPostTriggerConfig = z.infer<typeof FacebookCommentPostTriggerConfigSchema>;

const FacebookCommentPostVerificationAttemptDataSchema = z.object({
  comment: FacebookCommentSchema.nullable(),
  username: z.string().nullable(),
  userLink: z.string().nullable(),
});
export type FacebookCommentPostVerificationAttemptData = z.infer<
  typeof FacebookCommentPostVerificationAttemptDataSchema
>;

export type FacebookCommentPostTriggerStruct = TriggerIntegrationStruct<
  'facebook.comment-post',
  FacebookCommentPostTriggerConfig,
  FacebookCommentPostVerificationAttemptData
>;
export const FacebookCommentPostTriggerSpec: TriggerIntegrationSpec<FacebookCommentPostTriggerStruct> =
  {
    id: 'facebook.comment-post' as const,
    configurableMaxAttempts: false,
    configSchema: FacebookCommentPostTriggerConfigSchema,
    verificationAttemptDataSchema: FacebookCommentPostVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
