import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {FACEBOOK_URL} from '@cohort/shared/apps/facebook/common';
import type {FacebookTagInPostVerificationAttemptData} from '@cohort/shared/apps/facebook/triggers/tagInPost';
import {Trans} from 'react-i18next';

const FacebookTagInPostUnverifiedTriggerStepCompletionTitleComponent: React.FC<{
  data: FacebookTagInPostVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data}) => {
  return (
    <Trans
      i18nKey="triggers.tag-in-post-unverified.stepCompletionTitleComponent.success"
      ns="app-facebook"
      values={{username: data.username}}
      components={{
        userLink: <StepCompletionLink url={data.userPermalink ?? FACEBOOK_URL} />,
      }}
    />
  );
};

export default FacebookTagInPostUnverifiedTriggerStepCompletionTitleComponent;
