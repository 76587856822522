import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {YoutubeMerchantConnectorStruct} from '@cohort/shared/apps/youtube/merchantConnector';
import {YOUTUBE_URL} from '@cohort/shared-frontend/common/apps/youtube';
import {useEffect} from 'react';

const YoutubeSubscribeTriggerConfigComponent: React.FC<TriggerIntegrationConfigComponentProps> = ({
  connectionId,
  formReturn,
}) => {
  const merchant = useCurrentMerchant();
  const {data: profile} = useConnectionProfile<YoutubeMerchantConnectorStruct>(
    merchant.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    connectionId!,
    {enabled: !!connectionId}
  );
  const {setValue} = formReturn;

  useEffect(() => {
    if (!profile) {
      return;
    }

    setValue(
      'triggerIntegrationConfig.channelUrl',
      `${YOUTUBE_URL}/${profile.properties.channelHandle ?? profile.properties.channelTitle}`
    );
  }, [setValue, profile]);

  return null;
};

export default YoutubeSubscribeTriggerConfigComponent;
