/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {ListResourceParamsADto} from '@cohort/admin-schemas/resources';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {listResource} from '@cohort/merchants/lib/api/Resource';
import type {ResourceType} from '@cohort/shared/apps';
import type {ResourceStruct} from '@cohort/shared/apps/resource';

export const resourcesKeys = {
  resources: ['resources'] as const,
  listResource: (merchantId: string, resourceType: ResourceType, params?: ListResourceParamsADto) =>
    [...resourcesKeys.resources, merchantId, resourceType, 'list', params] as const,
};

export function useResources<R extends ResourceStruct = ResourceStruct>(
  merchantId: string,
  resourceType: ResourceType,
  params?: ListResourceParamsADto,
  options?: QueryOptions
) {
  return useCohortQuery({
    queryKey: resourcesKeys.listResource(merchantId, resourceType, params),
    queryFn: async () => listResource<R>(merchantId, resourceType, params),
    ...options,
  });
}
