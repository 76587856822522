import type {TriggerIntegrationSpec, UnverifiedTriggerStruct} from '@cohort/shared/apps/trigger';
import {UnverifiedTriggerBaseConfigSchema} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const TikTokRepostTiktokTriggerConfigSchema = UnverifiedTriggerBaseConfigSchema.extend({
  videoId: z.string(),
});
type TikTokRepostTiktokTriggerConfig = z.infer<typeof TikTokRepostTiktokTriggerConfigSchema>;

const TikTokRepostTiktokVerificationAttemptDataSchema = z.object({
  username: z.string(),
  url: z.string().url(),
});
export type TikTokRepostTiktokVerificationAttemptData = z.infer<
  typeof TikTokRepostTiktokVerificationAttemptDataSchema
>;

export type TikTokRepostTiktokTriggerStruct = UnverifiedTriggerStruct<
  'tiktok.repost-tiktok',
  TikTokRepostTiktokTriggerConfig,
  TikTokRepostTiktokVerificationAttemptData
>;
export const TikTokRepostTiktokTriggerSpec: TriggerIntegrationSpec<TikTokRepostTiktokTriggerStruct> =
  {
    id: 'tiktok.repost-tiktok' as const,
    configurableMaxAttempts: false,
    configSchema: TikTokRepostTiktokTriggerConfigSchema,
    verificationAttemptDataSchema: TikTokRepostTiktokVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
