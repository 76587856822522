import type {TriggerIntegration} from '@cohort/merchants/apps';
import {getFacebookPageInfoConfigComponent} from '@cohort/merchants/apps/facebook/triggers/FacebookPageInfoConfigComponent';
import FacebookLikePageTriggerUnverifiedStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/likePageUnverified/StepCompletionTitleComponent';
import type {FacebookLikePageUnverifiedTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePageUnverified';
import {FacebookLikePageUnverifiedTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePageUnverified';

export const FacebookLikePageUnverifiedTriggerIntegration: TriggerIntegration<FacebookLikePageUnverifiedTriggerStruct> =
  {
    spec: FacebookLikePageUnverifiedTriggerSpec,
    configComponent: getFacebookPageInfoConfigComponent({isUnverified: true}),
    stepCompletionTitleComponent: FacebookLikePageTriggerUnverifiedStepCompletionTitleComponent,
    getTitle: t => t('triggers.like-page-unverified.title', {ns: 'app-facebook'}),
  };
