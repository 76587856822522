import DatetimeInput from '@cohort/merchants/components/form/DatetimeInput';
import Input from '@cohort/merchants/components/form/input/Input';
import ListInput from '@cohort/merchants/components/form/ListInput';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {DataType} from '@cohort/shared/schema/common/rules/dataTypes';
import type {OperatorId} from '@cohort/shared/schema/common/rules/operators';
import {useEffect} from 'react';
import type {Control, FieldValues, Path, UseFormRegister} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type CriteriaInputValueProps<T extends FieldValues> = {
  operator: OperatorId;
  dataType: Exclude<DataType, 'resource' | 'resource_list'>;
  name: Path<T>;
  control: Control<T>;
  register: UseFormRegister<T>;
};

const CriteriaInputValue = <T extends FieldValues>({
  operator,
  dataType,
  register,
  control,
  name,
}: CriteriaInputValueProps<T>): JSX.Element | null => {
  const {t} = useTranslation('components', {
    keyPrefix: 'rules.criteria.criteriaInputValue',
  });
  const {field} = useController({
    name,
    control,
  });

  useEffect(() => {
    if (field.value === undefined || field.value === null) {
      if (dataType === 'string_list') {
        field.onChange([]);
      } else {
        field.onChange('');
      }
    }
  }, [dataType, field]);

  return match(dataType)
    .with('boolean', () => (
      <SelectInput
        name={name}
        options={[
          {value: true, label: t('true')},
          {value: false, label: t('false')},
        ]}
        register={register}
        control={control}
      />
    ))
    .with('date', () => (
      <DatetimeInput variant="date" control={control} name={name} register={register} />
    ))
    .with('null', () => null)
    .with('string_list', () => (
      <ListInput name={name} control={control} register={register} lowerCaseItems />
    ))
    .with('number', () => (
      <div className="flex items-start gap-4">
        <Input
          type="number"
          name={name}
          placeholder={t('placeholder')}
          register={register}
          control={control}
        />
        {['date.in_next_days', 'date.in_past_days'].includes(operator) && (
          <p className="mt-2 font-semibold">{t('days')}</p>
        )}
      </div>
    ))
    .with('string', () => (
      <Input
        name={name}
        placeholder={t('placeholder')}
        register={register}
        control={control}
        description={t('caseInsensitiveHint')}
      />
    ))
    .exhaustive();
};

export default CriteriaInputValue;
