import type {CohortADto} from '@cohort/admin-schemas/cohort';
import {ReferenceIdFormSchema} from '@cohort/admin-schemas/referenceId';
import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import {RadioCards} from '@cohort/merchants/components/form/RadioCards';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {cohortsKeys} from '@cohort/merchants/hooks/api/Cohorts';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {createCohort, patchCohort} from '@cohort/merchants/lib/api/Cohorts';
import {getCohortMembersRoute} from '@cohort/merchants/lib/Pages';
import {CohortTypeSchema} from '@cohort/shared/schema/common/cohort';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {zodResolver} from '@hookform/resolvers/zod';
import {Lightning, ListBullets} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';

interface CreateEditCohortModalProps {
  cohort?: CohortADto;
  onClose: () => void;
  redirectAfterCreate?: boolean;
}

export const CohortFormSchema = z.object({
  name: z.string().min(3, {message: 'errorTooShort3'}),
  referenceId: ReferenceIdFormSchema,
  type: CohortTypeSchema,
});
type CohortFormValues = z.infer<typeof CohortFormSchema>;

const CreateEditCohortModal: React.FC<CreateEditCohortModalProps> = ({
  cohort,
  onClose,
  redirectAfterCreate = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {t} = useTranslation('components', {keyPrefix: 'modals.createEditCohortModal'});

  const {register, handleSubmit, control, setError} = useForm<CohortFormValues>({
    resolver: zodResolver(CohortFormSchema),
    defaultValues: {
      name: cohort?.name ?? t('placeholderName'),
      type: cohort?.type ?? 'list',
      referenceId: cohort?.referenceId ?? '',
    },
  });

  const {isLoading, mutate: createCohortMutation} = useCohortMutation({
    mutationFn: async (data: CohortFormValues) =>
      cohort ? patchCohort(merchantId, cohort.id, data) : createCohort(merchantId, data),
    onSuccess: async newCohort => {
      await queryClient.invalidateQueries(cohortsKeys.list(merchantId));
      if (cohort) {
        queryClient.invalidateQueries(cohortsKeys.getById(merchantId, cohort.id));
      }
      if (redirectAfterCreate) {
        navigate(getCohortMembersRoute(newCohort.id).path);
      }
      onClose();
    },
    onError: (err: Error) => {
      if (isCohortError(err, ['cohort.reference-id-already-exists'])) {
        setError('referenceId', {message: 'errorNotAvailable'});
        return;
      }
      notify('error', err.message);
    },
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="[width:600px]">
        <form onSubmit={handleSubmit(data => createCohortMutation(data))}>
          <DialogHeader>
            <DialogTitle className="text-lg">
              {cohort ? t('titleEdit') : t('titleCreate')}
            </DialogTitle>
          </DialogHeader>
          <DialogBody className="space-y-6">
            <Input
              label={t('labelName')}
              type="text"
              name="name"
              register={register}
              control={control}
            />
            <Input
              type="text"
              name="referenceId"
              autoFocus={cohort !== undefined}
              register={register}
              control={control}
              label={t('labelReferenceId')}
              optional
            />
            <RadioCards
              name="type"
              disabled={cohort !== undefined}
              alignment="items-start"
              direction="row"
              label={t('labelType')}
              register={register}
              control={control}
              options={[
                {
                  label: t('labelList'),
                  description: <div className="w-32">{t('descriptionList')}</div>,
                  value: 'list',
                  prefix: (
                    <ListBullets
                      width={40}
                      height={40}
                      className="rounded-md bg-slate-50 p-2 text-muted-foreground"
                    />
                  ),
                },
                {
                  label: t('labelSegment'),
                  description: <div className="w-32">{t('descriptionSegment')}</div>,
                  value: 'segment',
                  prefix: (
                    <Lightning
                      width={40}
                      height={40}
                      className="rounded-md bg-slate-50 p-2 text-muted-foreground"
                    />
                  ),
                },
              ]}
            />
          </DialogBody>
          <DialogFooter>
            <Button variant="secondary" onClick={onClose}>
              {t('buttonCancel')}
            </Button>
            <Button type="submit" disabled={false} loading={isLoading}>
              {cohort ? t('buttonSave') : t('buttonCreate')}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateEditCohortModal;
