import type {ActionSpec, AppSpec, AppStruct, PerkIntegrationSpec} from '@cohort/shared/apps/app';
import {ListConnectedPagesActionSpec} from '@cohort/shared/apps/instagram/actions/listConnectedPages';
import {ListMediasActionSpec} from '@cohort/shared/apps/instagram/actions/listMedias';
import type {InstagramMerchantConnectorStruct} from '@cohort/shared/apps/instagram/merchantConnector';
import {InstagramMerchantConnectorSpec} from '@cohort/shared/apps/instagram/merchantConnector';
import {InstagramCloseFriendsListPerkIntegrationSpec} from '@cohort/shared/apps/instagram/perks/closeFriendsList';
import {InstagramCommentMediaTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import {InstagramFollowTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/follow';
import {InstagramLikeMediaTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/likeMedia';
import {InstagramTagInPostTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/tagInPost';
import type {InstagramUserConnectorStruct} from '@cohort/shared/apps/instagram/userConnector';
import {InstagramUserConnectorSpec} from '@cohort/shared/apps/instagram/userConnector';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {
  createAppUsernameUserPropertySpec,
  createBaseAppUserPropertySpec,
} from '@cohort/shared/apps/userProperty';

export const INSTAGRAM_APP_ID = 'instagram' as const;
export const INSTAGRAM_APP_NAME = 'Instagram' as const;

export const InstagramUserPropertiesSpecs = [
  ...createBaseAppUserPropertySpec(INSTAGRAM_APP_ID, INSTAGRAM_APP_NAME),
  createAppUsernameUserPropertySpec(INSTAGRAM_APP_ID, INSTAGRAM_APP_NAME),
  {
    id: 'instagram.media-count',
    dataType: 'number',
    name: 'Instagram Number of Media',
    scope: 'profile',
    profileKey: 'mediaCount',
  },
] as const satisfies ReadonlyArray<UserPropertySpec<UserPropertyStruct>>;
type InstagramUserPropertyIds = (typeof InstagramUserPropertiesSpecs)[number]['id'];

export const InstagramActionSpecs = [
  ListConnectedPagesActionSpec,
  ListMediasActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type InstagramActionIds = (typeof InstagramActionSpecs)[number]['id'];

export const InstagramPerkIntegrationSpecs = [
  InstagramCloseFriendsListPerkIntegrationSpec,
] as const satisfies ReadonlyArray<PerkIntegrationSpec>;
type InstagramPerkIntegrationIds = (typeof InstagramPerkIntegrationSpecs)[number]['id'];

export const InstagramTriggerIntegrationSpecs = [
  InstagramFollowTriggerIntegrationSpec,
  InstagramCommentMediaTriggerIntegrationSpec,
  InstagramLikeMediaTriggerIntegrationSpec,
  InstagramTagInPostTriggerIntegrationSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;
type InstagramTriggerIntegrationIds = (typeof InstagramTriggerIntegrationSpecs)[number]['id'];

export type InstagramAppStruct = AppStruct<
  'instagram',
  InstagramMerchantConnectorStruct,
  InstagramUserConnectorStruct,
  null,
  InstagramActionIds,
  never,
  InstagramPerkIntegrationIds,
  InstagramTriggerIntegrationIds,
  never,
  InstagramUserPropertyIds,
  never,
  never
>;

export const InstagramAppSpec: AppSpec<InstagramAppStruct> = {
  id: INSTAGRAM_APP_ID,
  name: INSTAGRAM_APP_NAME,
  category: 'social-media',
  merchantConnector: InstagramMerchantConnectorSpec,
  userConnector: InstagramUserConnectorSpec,
  syncSpec: null,
  actionSpecs: InstagramActionSpecs,
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: InstagramPerkIntegrationSpecs,
  triggerIntegrationSpecs: InstagramTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: InstagramUserPropertiesSpecs,
  resourceSpecs: [],
  mediaSpecs: [],
};
