import DataTypeIcon from '@cohort/merchants/components/DataTypeIcon';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import {useUserProperties} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useTranslation} from 'react-i18next';

const ExportUserEventsUserPropertySelect: React.FC = () => {
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('app-talon-one', {
    keyPrefix: 'sync.exportUserEventsUserPropertySelect',
  });

  const {data: userProperties, isFetched: isUserPropertiesFetched} = useUserProperties(
    merchant.id,
    {orderBy: 'name'}
  );

  const {register, control, watch} =
    useCohortForm<Extract<SyncConfigFormValues, {appId: 'talon-one'}>>();

  const isEnabled = watch('userEventExportEnabled');

  const userPropertyOptions =
    userProperties
      ?.filter(({dataType}) => dataType === 'string')
      .map(({name, id}) => ({label: name, value: id})) ?? [];

  return (
    <SelectInput
      name="userEventExportConfig.customerProfileIdUserPropertyId"
      register={register}
      control={control}
      isClearable
      placeholder={t('placeholder')}
      formatOptionLabel={data => {
        const dataType = userProperties?.find(property => property.id === data.value)?.dataType;
        return (
          <div className="flex items-center gap-2">
            {dataType && <DataTypeIcon dataType={dataType} />}
            <span>{data.label}</span>
          </div>
        );
      }}
      options={userPropertyOptions}
      disabled={!isEnabled || !isUserPropertiesFetched}
      label={t('label')}
      optional
    />
  );
};

export default ExportUserEventsUserPropertySelect;
