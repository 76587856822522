import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {LocalizedStringSchema} from '@cohort/shared/schema/common';
import {DataTypeSchema} from '@cohort/shared/schema/common/rules/dataTypes';
import {UserPropertyCriterionOperatorSchema} from '@cohort/shared/schema/common/rules/operators';
import {z} from 'zod';

export const CohortUserPropertyConditionMetTriggerConfigSchema = z.object({
  title: LocalizedStringSchema,
  userPropertyCriterion: z.object({
    userPropertyId: z.string().uuid(),
    operator: UserPropertyCriterionOperatorSchema,
    valueType: DataTypeSchema,
    value: z.unknown(),
  }),
});
export type CohortUserPropertyConditionMetTriggerConfig = z.infer<
  typeof CohortUserPropertyConditionMetTriggerConfigSchema
>;

export const CohortUserPropertyConditionMetVerificationAttemptDataSchema = z.object({
  title: LocalizedStringSchema,
});
export type CohortUserPropertyConditionMetVerificationAttemptData = z.infer<
  typeof CohortUserPropertyConditionMetVerificationAttemptDataSchema
>;

export type CohortUserPropertyConditionMetTriggerStruct = TriggerIntegrationStruct<
  'cohort.user-property-condition-met',
  CohortUserPropertyConditionMetTriggerConfig,
  CohortUserPropertyConditionMetVerificationAttemptData
>;

export const CohortUserPropertyConditionMetTriggerIntegrationSpec: TriggerIntegrationSpec<CohortUserPropertyConditionMetTriggerStruct> =
  {
    id: 'cohort.user-property-condition-met' as const,
    configurableMaxAttempts: false,
    configSchema: CohortUserPropertyConditionMetTriggerConfigSchema,
    verificationAttemptDataSchema: CohortUserPropertyConditionMetVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
  };
