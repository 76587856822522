import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {BaseUserEventExportConfigSchema} from '@cohort/shared/apps/sync';
import {z} from 'zod';

export const ComarchUserEventExportConfigSchema = BaseUserEventExportConfigSchema.extend({
  customerAccountIdUserPropertyId: z.string().nullable(), // The ID of the user property that contains Comarch's customer account ID. If null, all customers will be looked up by email.
});
export type ComarchUserEventExportConfig = z.infer<typeof ComarchUserEventExportConfigSchema>;

export type ComarchSyncStruct = SyncIntegrationStruct<
  never,
  never,
  never,
  ComarchUserEventExportConfig,
  never,
  never
>;

export const ComarchSyncSpec: SyncIntegrationSpec<ComarchSyncStruct> = {
  contentSyncConfigSchema: null,
  userSyncsConfigSchema: null,
  userSyncsStateSchema: null,
  userEventExportConfigSchema: ComarchUserEventExportConfigSchema,
  userExportConfigSchema: null,
  userExportStateSchema: null,
};
