type RowProps = {
  name: string;
  value: string | React.ReactNode;
};

const Row: React.FC<RowProps> = ({name, value}) => {
  return (
    <li key={name} className="grid grid-cols-3 bg-slate-50 py-4 text-slate-700 last:rounded-b-md">
      <div className="col-span-1 pr-2 text-left text-slate-500">{name}</div>
      <div className="col-span-2 whitespace-pre text-left font-medium text-slate-700">{value}</div>
    </li>
  );
};

type GridProps = {
  children: React.ReactElement<RowProps>[] | React.ReactElement<RowProps>;
};

const Grid: React.FC<GridProps> = ({children}) => {
  return <ul className="divide-y divide-slate-200">{children}</ul>;
};

export const PerkUsageLayout = {
  Grid,
  Row,
};
