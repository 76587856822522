import {EmailSchema, HexColorSchema} from '@cohort/shared/schema/common';
import {isFile, isFileList} from '@cohort/shared-frontend/utils/isFile';

export const hexColorValidator = (val: string): boolean => HexColorSchema.safeParse(val).success;

export const fileValidatorRequired = (file: string | File | FileList): boolean => {
  if (isFileList(file) && file.length === 0) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return file !== null && file !== undefined;
};

export const fileValidatorOptional = (file: string | File | FileList): boolean => {
  if (isFileList(file) && file.length === 0) {
    return true;
  }
  if (isFile(file)) {
    return true;
  }
  if (typeof file === 'string') {
    return true;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return file === null || file === undefined;
};

export const emailValidator = (email: string): boolean => {
  return EmailSchema.safeParse(email).success;
};
