import {EventIdSchema} from '@cohort/shared/apps';
import {asArray, DatetimeSchema} from '@cohort/shared/schema/common';
import {DateRangeSchema} from '@cohort/shared/schema/common/dates';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {UserEventsOrderBySchema} from '@cohort/shared/schema/common/userEvent';
import {z} from 'zod';

export const UserEventASchema = z.object({
  id: z.string().uuid(),
  eventId: EventIdSchema,
  properties: z.unknown(),
  createdAt: DatetimeSchema,
  campaignInternalName: z.string().nullable(),
  badgeInternalName: z.string().nullable(),
  perkInternalName: z.string().nullable(),
  digitalAssetCollectionInternalName: z.string().nullable(),
  cohortName: z.string().nullable(),
  isResourceDeleted: z.boolean(),
  eventDate: DatetimeSchema,
});
export type UserEventADto = z.infer<typeof UserEventASchema>;

export const ListUserEventsParamsASchema = PaginationParamsSchema.extend({
  userId: z.string().uuid(),
  eventIds: z.preprocess(asArray, z.array(EventIdSchema).optional()),
  search: z.string().optional(),
  orderBy: UserEventsOrderBySchema.default('eventDate'),
});
export type ListUserEventsParamsADto = z.infer<typeof ListUserEventsParamsASchema>;

export const ExportUserEventsParamsASchema = z.object({
  dateRange: DateRangeSchema.optional(),
  eventKinds: z.preprocess(asArray, z.array(EventIdSchema)).optional(),
});
export type ExportUserEventsParamsADto = z.infer<typeof ExportUserEventsParamsASchema>;
export type ExportUserEventsParamsInput = z.input<typeof ExportUserEventsParamsASchema>;
