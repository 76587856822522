import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {CohortOrderCreatedEventStruct} from '@cohort/shared/apps/cohort/userEvents/order/orderCreated';
import {CohortOrderCreatedEventSpec} from '@cohort/shared/apps/cohort/userEvents/order/orderCreated';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const navigate = useNavigate();
  const properties = userEvent.properties as CohortOrderCreatedEventStruct['Properties'];
  const campaignId = properties.campaignId;
  const campaignInternalName = properties.campaignInternalName;

  return (
    <Trans
      i18nKey="userEvents.order.created.detail"
      ns="app-cohort"
      values={{
        campaignInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getCampaignRoute(campaignId).path)
        ),
      }}
    />
  );
};

export const CohortOrderCreatedUserEvent: UserEvent<CohortOrderCreatedEventStruct> = {
  spec: CohortOrderCreatedEventSpec,
  detailComponent: DetailComponent,
};
