import {z} from 'zod';

export type TriggerIntegrationStruct<
  Id extends string = string,
  Config = unknown,
  VerificationAttemptData = unknown,
  VerificationAttemptInput = unknown,
> = {
  Id: Id;
  Config: Config;
  VerificationAttemptData: VerificationAttemptData;
  VerificationAttemptInput: VerificationAttemptInput;
};

export const UnverifiedTriggerBaseConfigSchema = z.object({
  url: z.string().url(),
});

const UnverifiedTriggerConfigSchema = UnverifiedTriggerBaseConfigSchema.passthrough();
type UnverifiedTriggerConfig = z.infer<typeof UnverifiedTriggerConfigSchema>;
export type UnverifiedTriggerStruct<
  Id extends string = string,
  Config extends UnverifiedTriggerConfig = UnverifiedTriggerConfig,
  VerificationAttemptData = unknown,
  VerificationAttemptInput = unknown,
> = {
  Id: Id;
  Config: Config;
  VerificationAttemptData: VerificationAttemptData;
  VerificationAttemptInput: VerificationAttemptInput;
};
export type TriggerIntegrationSpec<T extends TriggerIntegrationStruct = TriggerIntegrationStruct> =
  {
    id: T['Id'];
    configurableMaxAttempts: boolean;
    configSchema: z.ZodSchema<T['Config']>;
    verificationAttemptDataSchema: z.ZodSchema<T['VerificationAttemptData']>;
    verificationAttemptInputSchema: z.ZodSchema<T['VerificationAttemptInput']>;
    requiresMerchantConnection: boolean;
  };
