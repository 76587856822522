import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import UserPropertyCriteria from '@cohort/merchants/components/rules/criteria/UserPropertyCriteria';
import {useTranslation} from 'react-i18next';

const CohortUserPropertyConditionMetTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn}) => {
  const {register, control, watch} = formReturn;
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.user-property-condition-met.configComponent',
  });

  const selectedLanguage = watch('selectedLanguage');

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <label className="mb-1 block text-sm font-medium text-slate-700">
          {t('userPropertyCriterionLabel')}
        </label>
        <UserPropertyCriteria
          control={control}
          register={register}
          criteriaFieldName="triggerIntegrationConfig.userPropertyCriterion"
        />
      </div>
      <LocalizedInput
        type="text"
        label={t('labelTitleInput')}
        name="triggerIntegrationConfig.title"
        register={register}
        control={control}
        selectedLanguage={selectedLanguage}
      />
    </div>
  );
};

export default CohortUserPropertyConditionMetTriggerIntegrationConfigComponent;
