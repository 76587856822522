import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import type {ChallengeStepADto} from '@cohort/admin-schemas/challengeSteps';
import {
  Breadcrumb,
  BreadcrumbBadges,
  BreadcrumbContent,
  BreadcrumbEditableTitle,
  BreadcrumbLink,
} from '@cohort/merchants/components/Breadcrumb';
import Button from '@cohort/merchants/components/buttons/Button';
import {CampaignStatusBadge} from '@cohort/merchants/components/campaigns/CampaignStatusBadge';
import CampaignTypeBadge from '@cohort/merchants/components/campaigns/CampaignTypeBadge';
import QRCodeUrlButton from '@cohort/merchants/components/campaigns/challenges/QrCodeUrlButton';
import Header from '@cohort/merchants/components/Header';
import {CreateEntityModalFormSchema} from '@cohort/merchants/components/modals/CreateEntityModal';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '@cohort/merchants/components/modals/Dialog';
import {campaignsKeys, useChallengeSteps} from '@cohort/merchants/hooks/api/Campaigns';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {patchCampaign} from '@cohort/merchants/lib/api/Campaigns';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import CampaignDropdown from '@cohort/merchants/pages/campaigns/overview/CampaignDropdown';
import {CohortScanQrCodeTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/scanQrCode';
import type {Language} from '@cohort/shared/schema/common';
import {QrCode, Share} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';

type QRCodeChallengeManagerProps = {
  campaign: CampaignADto;
};

type QRCodeModalProps = {
  campaignStandaloneUrl: string;
  stepsWithQRCodeTrigger: ChallengeStepADto[];
  merchantDefaultLanguage: Language;
  onClose: () => void;
};

const QRCodeModal: React.FC<QRCodeModalProps> = ({
  campaignStandaloneUrl,
  stepsWithQRCodeTrigger,
  merchantDefaultLanguage,
  onClose,
}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.campaign.campaignPageBreadcrumb'});

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent className="w-[750px]">
        <DialogHeader className="space-y-6">
          <Header title={t('qrCodeModalTitle')} subtitle={t('qrCodeModalSubtitle')} />
        </DialogHeader>
        <DialogBody>
          <ul className="flex flex-col gap-4">
            {stepsWithQRCodeTrigger.map(step => (
              <li
                key={step.id}
                className="flex items-center justify-between rounded-xl border px-4 py-2 shadow-sm"
              >
                {step.title[merchantDefaultLanguage]}
                <QRCodeUrlButton stepId={step.id} campaignStandaloneUrl={campaignStandaloneUrl} />
              </li>
            ))}
          </ul>
        </DialogBody>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button onClick={onClose}>{t('closeModalBtn')}</Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const QRCodeChallengeManager: React.FC<QRCodeChallengeManagerProps> = ({campaign}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.campaign.campaignPageBreadcrumb'});
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);

  const challenge = campaign.challenge;
  const {data: challengeSteps} = useChallengeSteps(merchant.id, campaign.id, {
    enabled: campaign.type === 'challenge',
  });

  if (!challenge || !challengeSteps) {
    return null;
  }
  const stepsWithQRCodeTrigger = challengeSteps.filter(step =>
    step.triggers.some(
      trigger => trigger.triggerIntegrationId === CohortScanQrCodeTriggerIntegrationSpec.id
    )
  );

  if (stepsWithQRCodeTrigger.length === 0) {
    return null;
  }
  return (
    <Fragment>
      <Button variant="secondary" onClick={() => setIsQRCodeModalOpen(true)}>
        <QrCode size={20} className="mr-2 text-slate-400" />
        {t('qrCodeUrl')}
      </Button>
      {isQRCodeModalOpen && challenge.standaloneUrl && (
        <QRCodeModal
          campaignStandaloneUrl={challenge.standaloneUrl}
          stepsWithQRCodeTrigger={stepsWithQRCodeTrigger}
          merchantDefaultLanguage={merchant.defaultLanguage}
          onClose={() => setIsQRCodeModalOpen(false)}
        />
      )}
    </Fragment>
  );
};

const CampaignPageBreadcrumb = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.campaign.campaignPageBreadcrumb'});
  const campaign = useCurrentCampaign();
  const queryClient = useQueryClient();

  const {mutate: updateCampaignInternalName} = useCohortMutation({
    mutationFn: async (name: string) =>
      patchCampaign(merchantId, campaign.id, {internalName: name}),
    notifySuccessMessage: t('notificationSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(campaignsKeys.list(merchantId));
      queryClient.invalidateQueries(campaignsKeys.getById(merchantId, campaign.id));
    },
  });

  return (
    <Breadcrumb>
      <BreadcrumbContent>
        <BreadcrumbLink name={t('name')} path={getCampaignsRoute().path} />
        <BreadcrumbEditableTitle
          value={campaign.internalName}
          onChange={(name: string) => updateCampaignInternalName(name)}
          validator={(internalName: string) =>
            CreateEntityModalFormSchema.safeParse({internalName}).success
          }
        />
        <BreadcrumbBadges>
          {campaign.type ? (
            <CampaignTypeBadge key="type" type={campaign.type} />
          ) : (
            <Fragment key="type" />
          )}
          <CampaignStatusBadge key="status" size="small" status={campaign.status} />
        </BreadcrumbBadges>
      </BreadcrumbContent>
      <div className="flex items-center gap-4">
        {campaign.status !== 'draft' && <QRCodeChallengeManager campaign={campaign} />}
        {campaign.type !== 'airdrop' && campaign.status !== 'draft' && (
          <Button
            variant="secondary"
            onClick={() => {
              navigator.clipboard.writeText(
                campaign.challenge?.standaloneUrl ?? campaign.store?.shopUrl ?? ''
              );
              notify('success', t('shareSuccess'));
            }}
          >
            <Share className="mr-2 text-slate-400" size={20} />
            {t('shareBtn')}
          </Button>
        )}
        <CampaignDropdown campaign={campaign} />
      </div>
    </Breadcrumb>
  );
};

export default CampaignPageBreadcrumb;
