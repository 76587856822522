import {
  DatetimeSchema,
  EmailSchema,
  LanguageSchema,
  UserIdOrEmailRefinement,
} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const MembershipPassPSchema = z.object({
  memberHandle: z.string().nullable(),
  memberId: z.number(),
  subscriptionDate: DatetimeSchema.nullable(),
});
export type MembershipPassPDto = z.infer<typeof MembershipPassPSchema>;

export const UserPSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  membership: MembershipPassPSchema,
  notificationsEnabled: z.boolean(),
  lang: LanguageSchema,
  walletAddress: z.string().nullable(),
  createdAt: DatetimeSchema,
});
export type UserPDto = z.infer<typeof UserPSchema>;

// Requests

export const SearchUserQueryPSchema = z.object({
  email: EmailSchema,
});
export type SearchUserQueryPDto = z.infer<typeof SearchUserQueryPSchema>;

export const SearchUserResponsePSchema = z.object({
  user: UserPSchema.nullable(),
});
export type SearchUserResponsePDto = z.infer<typeof SearchUserResponsePSchema>;

export const CreateUserDataPSchema = z.object({
  email: EmailSchema,
  lang: z.string().optional().nullable(),
  createWallet: z.boolean().optional(),
});
export type CreateUserDataPDto = z.infer<typeof CreateUserDataPSchema>;

export const UpdateUserDataPSchema = z.object({
  lang: LanguageSchema.optional(),
  notificationsEnabled: z.boolean().optional(),
});
export type UpdateUserDataPDto = z.infer<typeof UpdateUserDataPSchema>;

export const GetSigninLinkResponsePSchema = z.object({
  signinLink: z.string(),
});
export type GetSigninLinkResponsePDto = z.infer<typeof GetSigninLinkResponsePSchema>;

export const CreateAuthTokenResponsePSchema = z.object({
  authToken: z.string(),
});
export type CreateAuthTokenResponsePDto = z.infer<typeof CreateAuthTokenResponsePSchema>;

export const CreateAuthTokenDataPSchema = z
  .object({
    userId: z.string().optional().nullable(),
    email: EmailSchema.optional().nullable(),
    lang: LanguageSchema.optional().nullable(),
  })
  .superRefine(UserIdOrEmailRefinement);
export type CreateAuthTokenDataPDto = z.infer<typeof CreateAuthTokenDataPSchema>;
