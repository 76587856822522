import type {
  ActionSpec,
  AppSpec,
  AppStruct,
  NotificationIntegrationSpec,
  PerkIntegrationSpec,
} from '@cohort/shared/apps/app';
import {CreateQrCodeActionSpec} from '@cohort/shared/apps/cohort/actions/createQrCode';
import {CohortArticleMediaSpec} from '@cohort/shared/apps/cohort/medias/article';
import {CohortImageMediaSpec} from '@cohort/shared/apps/cohort/medias/image';
import {CohortLinkMediaSpec} from '@cohort/shared/apps/cohort/medias/link';
import {CohortVideoMediaSpec} from '@cohort/shared/apps/cohort/medias/video';
import {CohortCustomRequestNotificationIntegrationSpec} from '@cohort/shared/apps/cohort/notifications/customRequest';
import {CohortEmailNotificationIntegrationSpec} from '@cohort/shared/apps/cohort/notifications/email';
import {CohortFormPerkIntegrationSpec} from '@cohort/shared/apps/cohort/perks/form';
import {CohortQrCodePerkIntegrationSpec} from '@cohort/shared/apps/cohort/perks/qrCode';
import {CohortAppResourceSpec} from '@cohort/shared/apps/cohort/resources/app';
import {CohortBadgeResourceSpec} from '@cohort/shared/apps/cohort/resources/badge';
import {CohortChallengeResourceSpec} from '@cohort/shared/apps/cohort/resources/challenge';
import {CohortCohortResourceSpec} from '@cohort/shared/apps/cohort/resources/cohort';
import {CohortContentResourceSpec} from '@cohort/shared/apps/cohort/resources/content';
import {CohortDigitalAssetResourceSpec} from '@cohort/shared/apps/cohort/resources/digitalAsset';
import {CohortDigitalAssetCollectionResourceSpec} from '@cohort/shared/apps/cohort/resources/digitalAssetCollection';
import {CohortPerkResourceSpec} from '@cohort/shared/apps/cohort/resources/perk';
import {CohortBeInCohortTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/beInCohort';
import {CohortDigitalAssetOwnedTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/digitalAssetOwned';
import {CohortFillFormTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/fillForm';
import {CohortPerkUsedTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/perkUsed';
import {CohortScanQrCodeTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/scanQrCode';
import {CohortUserPropertyConditionMetTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/userPropertyConditionMet';
import {CohortVisitLinkTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/visitLink';
import {CohortAppConnectedEventSpec} from '@cohort/shared/apps/cohort/userEvents/appConnected';
import {CohortAppDisconnectedEventSpec} from '@cohort/shared/apps/cohort/userEvents/appDisconnected';
import {CohortBadgeGainedEventSpec} from '@cohort/shared/apps/cohort/userEvents/badge/badgeGained';
import {CohortBadgeLostEventSpec} from '@cohort/shared/apps/cohort/userEvents/badge/badgeLost';
import {CohortChallengeCompletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeCompleted';
import {CohortChallengeLeftEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeLeft';
import {CohortChallengeOptedInEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeOptedIn';
import {CohortChallengeStepCompletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeStepCompleted';
import {CohortNewChallengeAvailableEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/newChallengeAvailable';
import {CohortNewChallengeStepAvailableEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/newChallengeStepAvailable';
import {CohortCohortJoinedEventSpec} from '@cohort/shared/apps/cohort/userEvents/cohort/cohortJoined';
import {CohortCohortLeftEventSpec} from '@cohort/shared/apps/cohort/userEvents/cohort/cohortLeft';
import {CohortDigitalAssetDeletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetDeleted';
import {CohortDigitalAssetReceivedEventSpec} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetReceived';
import {CohortDigitalAssetTransferredEventSpec} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetTransferred';
import {CohortOrderCreatedEventSpec} from '@cohort/shared/apps/cohort/userEvents/order/orderCreated';
import {CohortPerkReceivedEventSpec} from '@cohort/shared/apps/cohort/userEvents/perk/perkReceived';
import {CohortPerkRestoredEventSpec} from '@cohort/shared/apps/cohort/userEvents/perk/perkRestored';
import {CohortPerkRevokedEventSpec} from '@cohort/shared/apps/cohort/userEvents/perk/perkRevoked';
import {CohortPerkUsedEventSpec} from '@cohort/shared/apps/cohort/userEvents/perk/perkUsed';
import {CohortUserCreatedEventSpec} from '@cohort/shared/apps/cohort/userEvents/userCreated';
import {CohortUserDeletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/userDeleted';
import {CohortUsernameChangedEventSpec} from '@cohort/shared/apps/cohort/userEvents/usernameChanged';
import {CohortXpsVisitedEventSpec} from '@cohort/shared/apps/cohort/userEvents/xpsVisited';
import {CohortChallengesUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/challenges';
import {CohortChallengesCountUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/challengesCount';
import {CohortDigitalAssetsUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/digitalAssets';
import {CohortDigitalAssetsCountUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/digitalAssetsCount';
import {CohortEmailUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/email';
import {CohortEngagementScoreUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/engagementScore';
import {CohortLanguageUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/language';
import {CohortLastActiveUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/lastActive';
import {CohortMemberHandleUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/memberHandle';
import {CohortMemberSinceUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/memberSince';
import {CohortPerksUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/perks';
import {CohortPerksCountUserPropertySpec} from '@cohort/shared/apps/cohort/userProperties/perksCount';
import type {MediaSpec} from '@cohort/shared/apps/media';
import type {ResourceSpec} from '@cohort/shared/apps/resource';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {UserEventSpec} from '@cohort/shared/apps/userEvent';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export const CohortActionsSpecs = [
  CreateQrCodeActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type CohortActionIds = (typeof CohortActionsSpecs)[number]['id'];

export const CohortPerkIntegrationSpecs = [
  CohortQrCodePerkIntegrationSpec,
  CohortFormPerkIntegrationSpec,
] as const satisfies ReadonlyArray<PerkIntegrationSpec>;
type CohortPerkIntegrationIds = (typeof CohortPerkIntegrationSpecs)[number]['id'];

export const CohortTriggerIntegrationSpecs = [
  CohortBeInCohortTriggerIntegrationSpec,
  CohortDigitalAssetOwnedTriggerIntegrationSpec,
  CohortFillFormTriggerIntegrationSpec,
  CohortPerkUsedTriggerIntegrationSpec,
  CohortScanQrCodeTriggerIntegrationSpec,
  CohortVisitLinkTriggerIntegrationSpec,
  CohortUserPropertyConditionMetTriggerIntegrationSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;
type CohortTriggerIntegrationIds = (typeof CohortTriggerIntegrationSpecs)[number]['id'];

export const CohortNotificationIntegrationSpecs = [
  CohortEmailNotificationIntegrationSpec,
  CohortCustomRequestNotificationIntegrationSpec,
] as const satisfies ReadonlyArray<NotificationIntegrationSpec>;
type CohortNotificationIntegrationIds = (typeof CohortNotificationIntegrationSpecs)[number]['id'];

export const CohortEventSpecs = [
  CohortAppConnectedEventSpec,
  CohortAppDisconnectedEventSpec,
  CohortBadgeGainedEventSpec,
  CohortBadgeLostEventSpec,
  CohortCohortJoinedEventSpec,
  CohortCohortLeftEventSpec,
  CohortDigitalAssetReceivedEventSpec,
  CohortDigitalAssetDeletedEventSpec,
  CohortDigitalAssetTransferredEventSpec,
  CohortChallengeCompletedEventSpec,
  CohortChallengeLeftEventSpec,
  CohortChallengeOptedInEventSpec,
  CohortChallengeStepCompletedEventSpec,
  CohortNewChallengeAvailableEventSpec,
  CohortNewChallengeStepAvailableEventSpec,
  CohortOrderCreatedEventSpec,
  CohortPerkReceivedEventSpec,
  CohortPerkRestoredEventSpec,
  CohortPerkRevokedEventSpec,
  CohortPerkUsedEventSpec,
  CohortUserCreatedEventSpec,
  CohortUserDeletedEventSpec,
  CohortUsernameChangedEventSpec,
  CohortXpsVisitedEventSpec,
] as const satisfies ReadonlyArray<UserEventSpec>;
type CohortEventIds = (typeof CohortEventSpecs)[number]['id'];

export const CohortUserPropertiesSpecs = [
  CohortDigitalAssetsCountUserPropertySpec,
  CohortDigitalAssetsUserPropertySpec,
  CohortEmailUserPropertySpec,
  CohortEngagementScoreUserPropertySpec,
  CohortChallengesCountUserPropertySpec,
  CohortChallengesUserPropertySpec,
  CohortLanguageUserPropertySpec,
  CohortLastActiveUserPropertySpec,
  CohortMemberHandleUserPropertySpec,
  CohortMemberSinceUserPropertySpec,
  CohortPerksCountUserPropertySpec,
  CohortPerksUserPropertySpec,
] as const satisfies ReadonlyArray<UserPropertySpec<UserPropertyStruct>>;
type CohortUserPropertyIds = (typeof CohortUserPropertiesSpecs)[number]['id'];

export const CohortResourceSpecs = [
  CohortAppResourceSpec,
  CohortBadgeResourceSpec,
  CohortCohortResourceSpec,
  CohortContentResourceSpec,
  CohortDigitalAssetResourceSpec,
  CohortDigitalAssetCollectionResourceSpec,
  CohortChallengeResourceSpec,
  CohortPerkResourceSpec,
] as const satisfies ReadonlyArray<ResourceSpec>;
type CohortResourceTypes = (typeof CohortResourceSpecs)[number]['id'];

export const CohortMediaSpecs = [
  CohortArticleMediaSpec,
  CohortImageMediaSpec,
  CohortLinkMediaSpec,
  CohortVideoMediaSpec,
] as const satisfies ReadonlyArray<MediaSpec>;
type CohortMediaTypes = (typeof CohortMediaSpecs)[number]['kind'];

export type CohortAppStruct = AppStruct<
  'cohort',
  null,
  null,
  null,
  CohortActionIds,
  CohortNotificationIntegrationIds,
  CohortPerkIntegrationIds,
  CohortTriggerIntegrationIds,
  CohortEventIds,
  CohortUserPropertyIds,
  CohortResourceTypes,
  CohortMediaTypes
>;

export const CohortAppSpec: AppSpec<CohortAppStruct> = {
  id: 'cohort',
  name: 'Cohort',
  merchantConnector: null,
  userConnector: null,
  syncSpec: null,
  actionSpecs: [CreateQrCodeActionSpec],
  notificationIntegrationSpecs: CohortNotificationIntegrationSpecs,
  perkIntegrationSpecs: CohortPerkIntegrationSpecs,
  triggerIntegrationSpecs: CohortTriggerIntegrationSpecs,
  userEventSpecs: CohortEventSpecs,
  userPropertySpecs: CohortUserPropertiesSpecs,
  resourceSpecs: CohortResourceSpecs,
  mediaSpecs: CohortMediaSpecs,
};
