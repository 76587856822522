import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {useCurrentContent} from '@cohort/merchants/hooks/contexts/currentContent';
import {useContentPageStore} from '@cohort/merchants/hooks/stores/contentPage';
import {getContentAudienceRoute, getContentSettingsRoute} from '@cohort/merchants/lib/Pages';
import {NewspaperClipping, Users} from '@phosphor-icons/react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const ContentPageMenu = (): JSX.Element => {
  const content = useCurrentContent();
  const {t} = useTranslation('pages', {keyPrefix: 'contents.content.contentPageMenu'});
  const menu = useContentPageStore(state => state.menu);

  if (menu) {
    return <Fragment>{menu}</Fragment>;
  }

  return (
    <AsidePageMenu
      entries={[
        {
          name: t('contentSettings'),
          path: getContentSettingsRoute(content.id).path,
          icon: <NewspaperClipping size={20} />,
          testId: 'content-settings-link',
        },
        {
          name: t('audience'),
          path: getContentAudienceRoute(content.id).path,
          icon: <Users size={20} />,
          testId: 'audience-link',
        },
      ]}
    />
  );
};

export default ContentPageMenu;
