import type {TriggerIntegrationSpec, UnverifiedTriggerStruct} from '@cohort/shared/apps/trigger';
import {UnverifiedTriggerBaseConfigSchema} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const InstagramLikeMediaTriggerConfigSchema = UnverifiedTriggerBaseConfigSchema.extend({
  mediaId: z.string(),
});
type InstagramLikeMediaTriggerConfig = z.infer<typeof InstagramLikeMediaTriggerConfigSchema>;

const InstagramLikeMediaVerificationAttemptDataSchema = z.object({
  username: z.string(),
  url: z.string().url(),
});
export type InstagramLikeMediaVerificationAttemptData = z.infer<
  typeof InstagramLikeMediaVerificationAttemptDataSchema
>;

export type InstagramLikeMediaTriggerStruct = UnverifiedTriggerStruct<
  'instagram.like-media',
  InstagramLikeMediaTriggerConfig,
  InstagramLikeMediaVerificationAttemptData
>;
export const InstagramLikeMediaTriggerIntegrationSpec: TriggerIntegrationSpec<InstagramLikeMediaTriggerStruct> =
  {
    id: 'instagram.like-media' as const,
    configurableMaxAttempts: false,
    configSchema: InstagramLikeMediaTriggerConfigSchema,
    verificationAttemptDataSchema: InstagramLikeMediaVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
