import LinkPreviewCard from '@cohort/components-xps/components/apps/cohort/LinkPreviewCard';
import {Card} from '@cohort/components-xps/components/cards/Card';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import MediaContentInfos from '@cohort/components-xps/components/contents/MediaContentInfos';
import type {CohortLinkMediaStruct} from '@cohort/shared/apps/cohort/medias/link';

const MediaContentCohortLink: React.FC<MediaContentProps<CohortLinkMediaStruct>> = ({
  title,
  config,
  description,
}) => (
  <Card className="flex-col space-y-6">
    <MediaContentInfos title={title} description={description} />
    <LinkPreviewCard
      urlData={{
        url: config?.url ?? null,
        imageSrc: config?.imageSrc ?? null,
        title: config?.title ?? null,
      }}
    />
  </Card>
);

export default MediaContentCohortLink;
