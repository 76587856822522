import {InstagramMediaSchema} from '@cohort/shared/apps/instagram/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const InstagramTagInPostTriggerConfigSchema = z.object({});

export type InstagramTagInPostTriggerConfig = z.infer<typeof InstagramTagInPostTriggerConfigSchema>;

export const InstagramTagInPostVerificationAttemptDataSchema = z.object({
  media: InstagramMediaSchema.nullable(),
  username: z.string().nullable(),
});
export type InstagramTagInPostVerificationAttemptData = z.infer<
  typeof InstagramTagInPostVerificationAttemptDataSchema
>;

export type InstagramTagInPostTriggerStruct = TriggerIntegrationStruct<
  'instagram.tag-in-post',
  InstagramTagInPostTriggerConfig,
  InstagramTagInPostVerificationAttemptData
>;
export const InstagramTagInPostTriggerIntegrationSpec: TriggerIntegrationSpec<InstagramTagInPostTriggerStruct> =
  {
    id: 'instagram.tag-in-post' as const,
    configurableMaxAttempts: false,
    configSchema: InstagramTagInPostTriggerConfigSchema,
    verificationAttemptDataSchema: InstagramTagInPostVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
