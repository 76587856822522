import {extendApi} from '@anatine/zod-openapi';
import Color from 'color';
import parser from 'cron-parser';
import {isValid, toDate} from 'date-fns';
import sanitizeHtml from 'sanitize-html';
import {z} from 'zod';

export const CohortAppSchema = z.enum(['merchants', 'wallet']);
export type CohortApp = z.infer<typeof CohortAppSchema>;

export type PromiseOr<T> = Promise<T> | T;

export const SUPPORTED_LANGUAGES = [
  'ar',
  'zh',
  'cs',
  'nl',
  'en',
  'fr',
  'de',
  'el',
  'id',
  'it',
  'ja',
  'ko',
  'fa',
  'pl',
  'pt',
  'ru',
  'es',
  'tr',
  'uk',
  'vi',
] as const;

export const LANGUAGE_FLAGS: Record<Language, string> = {
  ar: '🇸🇦', // Arabic
  zh: '🇨🇳', // Chinese
  cs: '🇨🇿', // Czech
  nl: '🇳🇱', // Dutch
  en: '🇬🇧', // English
  fr: '🇫🇷', // French
  de: '🇩🇪', // German
  el: '🇬🇷', // Greek
  id: '🇮🇩', // Indonesian
  it: '🇮🇹', // Italian
  ja: '🇯🇵', // Japanese
  ko: '🇰🇷', // Korean
  fa: '🇮🇷', // Persian
  pl: '🇵🇱', // Polish
  pt: '🇵🇹', // Portuguese
  ru: '🇷🇺', // Russian
  es: '🇪🇸', // Spanish
  tr: '🇹🇷', // Turkish
  uk: '🇺🇦', // Ukrainian
  vi: '🇻🇳', // Vietnamese
};

export const LanguageSchema = z.enum(SUPPORTED_LANGUAGES);
export type Language = z.infer<typeof LanguageSchema>;

export const WEBAPP_SUPPORTED_LANGUAGES = ['en', 'fr', 'es'] as const;

export const WebappLanguageSchema = z.enum(WEBAPP_SUPPORTED_LANGUAGES);
export type WebappLanguage = z.infer<typeof WebappLanguageSchema>;

export const LocalizationConfigSchema = z.object({
  desiredLanguage: LanguageSchema,
  supportedLanguages: z.array(LanguageSchema),
  fallbackLanguage: LanguageSchema.optional(),
});
export type LocalizationConfig = z.infer<typeof LocalizationConfigSchema>;

export const LocalizedStringSchema = z
  .record(LanguageSchema, z.string().min(3, 'errorTooShort3').nullable())
  .describe(`A mapping of locale to string. Example: {en: 'Hello', fr: 'Bonjour'}`);
export type LocalizedString = z.infer<typeof LocalizedStringSchema>;

export const richTextSchemaTransform = (value: string): string =>
  sanitizeHtml(value, {
    disallowedTagsMode: 'escape',
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      // Needed for templating in Rich Text
      span: ['data-*'],
    },
  });

export const RichTextSchema = z
  .string()
  .min(10, 'errorTooShort3')
  .transform(richTextSchemaTransform);
export type RichText = z.infer<typeof RichTextSchema>;

export const LocalizedRichTextSchema = z
  .record(LanguageSchema, RichTextSchema.nullable())
  .describe(
    `A mapping of locale to rich text. Example: {en: '<p>Hello</p>', fr: '<p>Bonjour</p>'}`
  );
export type LocalizedRichText = z.infer<typeof LocalizedRichTextSchema>;

export const DateSchema = z
  .string()
  .regex(/^\d{4}-\d{2}-\d{2}$/u)
  .refine(value => isValid(toDate(value)));

// https://github.com/colinhacks/zod#dates
// Allows both Date and strings
export const DatetimeSchema = z.preprocess(arg => {
  if (typeof arg === 'string' || arg instanceof Date) {
    return new Date(arg);
  }
  return arg;
}, z.date());

export const SlugSchema = z.string().regex(/^[a-z0-9-]+$/u, 'errorInvalidSlug');

// found on https://emailregex.com/index.html
// added \s* at stard and end to allow whitspaces before and after
export const EmailSchema = extendApi(
  z
    .string({
      required_error: 'Please enter a valid email',
      invalid_type_error: 'Please enter a valid email',
    })
    // regex from https://emailregex.com/index.html
    .regex(
      // eslint-disable-next-line no-useless-escape
      /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/g,
      'Please enter a valid email'
    )
    .transform(value => value.trim().toLowerCase()),
  {
    format: 'email',
    description: 'Email address',
  }
);

export const PositiveIntegerSchema = z
  .number()
  .int()
  .refine(n => n > 0, 'Must be an integer greater than 0');

export const CronSchema = z.string().superRefine((value: string, ctx: z.RefinementCtx) => {
  try {
    parser.parseExpression(value);
    return true;
  } catch {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Invalid cron expression: ${value}`,
    });
    return false;
  }
});

const HEX_COLOR_ERROR_MESSAGE =
  'Color code must be in the format #RGB, #RGBA, #RRGGBB or #RRGGBBAA';
export const HexColorSchema = z
  .string()
  .min(4, HEX_COLOR_ERROR_MESSAGE)
  .max(9, HEX_COLOR_ERROR_MESSAGE)
  .regex(
    /^#[A-Fa-f0-9]{6}$|^#[A-Fa-f0-9]{3}$|^#[A-Fa-f0-9]{4}$|^#[A-Fa-f0-9]{8}$/u,
    HEX_COLOR_ERROR_MESSAGE
  );

export const DEFAULT_BACKGROUND_COLOR = '#F8FAFC';
export const DEFAULT_ACCENT_COLOR = '#3B82F6';

export function getTextColor(backgroundColor: string): string {
  try {
    HexColorSchema.parse(backgroundColor);
    return Color(backgroundColor).isDark() ? '#fff' : '#000';
  } catch {
    return '#000';
  }
}

export const BooleanQueryStringSchema = z.enum(['true', 'false']).transform(i => i === 'true');

export function asArray(value: undefined): undefined;
export function asArray<T>(value: T | T[]): T[];
export function asArray<T>(value: T | T[] | undefined): T[] | undefined {
  if (value === undefined) {
    return undefined;
  }
  return Array.isArray(value) ? value : [value];
}

export const UserIdOrEmailRefinement = (
  request: {
    email?: string | null;
    userId?: string | null;
  },
  ctx: z.RefinementCtx
): {message: string; path: string[]} | boolean => {
  const emailSet = request.email !== undefined && request.email !== null;
  const userIdSet = request.userId !== undefined && request.userId !== null;

  if (!emailSet && !userIdSet) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'One of the following 2 options must be provided for identifying the user: email, userId',
      path: ['email', 'userId'],
    });
    return false;
  }
  if (emailSet && userIdSet) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'Only one of the following 2 options must be provided for identifying the user: email, userId',
      path: ['email', 'userId'],
    });
    return false;
  }

  return true;
};

export const DiscountValueRefinement = (
  request: {
    value?: number | null;
    type?: string | null;
  },
  ctx: z.RefinementCtx
): {message: string; path: string[]} | boolean => {
  switch (request.type) {
    case 'percentage':
      if (!z.number().int().min(0).max(100).safeParse(request.value).success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Percentage must be a positive integer between 0 and 100',
          path: ['value'],
        });
      }
      return false;
    case 'fixed-amount':
      if (!z.number().step(0.01).min(0).safeParse(request.value).success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Amount must be a positive number',
          path: ['value'],
        });
      }
      return false;
    default:
      return true;
  }
};

export const JwtAuthTokenSchema = z.object({
  keyId: z.string().startsWith('auth-token:'),
  userId: z.string().uuid(),
  userEmail: EmailSchema,
  lang: LanguageSchema,
  merchantId: z.string().uuid(),
  iat: z.number().int(),
  exp: z.number().int(),
  aud: z.string().url(),
});
export type JwtAuthToken = z.infer<typeof JwtAuthTokenSchema>;

export const Base64EncodedStringSchema = z
  .string()
  .transform(encoded => Buffer.from(encoded, 'base64').toString());

export const MetadataSchema = z.array(
  z.object({
    key: z.string().trim().min(1),
    value: z.string(),
  })
);
export type Metadata = z.infer<typeof MetadataSchema>;

// Used for exports and webhooks when the metadata is formatted in JSON.
export const MetadataFormattedSchema = z.record(z.string());
export type MetadataFormatted = z.infer<typeof MetadataFormattedSchema>;
