import {DigitalAssetCollectionASchema} from '@cohort/admin-schemas/digitalAssetCollection';
import {PerkASchema} from '@cohort/admin-schemas/perk';
import {MAX_CAMPAIGN_SIZE} from '@cohort/shared/constants';
import {
  DatetimeSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const TotalSupplySchema = z.number().int().min(1).max(MAX_CAMPAIGN_SIZE);

export const RewardDistributionSchema = z.union([
  z.object({
    distributionMode: z.literal('direct'),
    unlockMode: z.null(),
    unlockAt: z.null(),
  }),
  z.object({
    distributionMode: z.literal('none'),
    unlockMode: z.null(),
    unlockAt: z.null(),
  }),
  z.object({
    distributionMode: z.literal('random'),
    unlockMode: z.literal('instant'),
    unlockAt: z.null(),
  }),
  z.object({
    distributionMode: z.literal('random'),
    unlockMode: z.literal('deferred'),
    unlockAt: z.date(),
  }),
]);

export const RewardConfigurationASchema = z.object({
  id: z.string().uuid(),
  title: LocalizedStringSchema,
  description: LocalizedRichTextSchema,
  imageFileKey: z.string().nullable(),
  distributionMode: z.enum(['direct', 'none', 'random']),
  unlockMode: z.enum(['instant', 'deferred']).nullable(),
  unlockAt: z.date().nullable(),
  unlockedAt: z.date().nullable(),
  collections: z.array(
    z.object({
      collectionId: z.string().uuid(),
      collection: DigitalAssetCollectionASchema,
      updatedAt: DatetimeSchema,
      totalSupply: z.number().nullable(),
      numClaimed: z.number(),
      availableSupply: z.number().nullable(),
    })
  ),
  perks: z.array(
    z.object({
      perkId: z.string().uuid(),
      revocable: z.boolean(),
      perk: PerkASchema,
      updatedAt: DatetimeSchema,
      totalSupply: z.number().nullable(),
      numClaimed: z.number(),
      availableSupply: z.number().nullable(),
    })
  ),
  totalSupply: z.number().nullable(),
  availableSupply: z.number().nullable(),
  numClaimed: z.number(),
});
export type RewardConfigurationADto = z.infer<typeof RewardConfigurationASchema>;

export const UpdateRewardConfigurationASchema = z
  .object({
    title: LocalizedStringSchema,
    description: LocalizedRichTextSchema,
    imageFileKey: z.string().nullable(),
    distribution: RewardDistributionSchema,
    collections: z
      .array(
        z.object({
          collectionId: z.string().uuid(),
          totalSupply: TotalSupplySchema.nullable().optional(),
        })
      )
      .optional(),
    perks: z.array(
      z.object({
        perkId: z.string().uuid(),
        revocable: z.boolean().optional(),
        totalSupply: TotalSupplySchema.nullable().optional(),
      })
    ),
    totalSupply: TotalSupplySchema.nullable(),
  })
  .partial();
