// i18nOwl-ignore [app-google-fit.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import translationEn from '@cohort/merchants/apps/google-fit/locales/en.json';
import translationFr from '@cohort/merchants/apps/google-fit/locales/fr.json';
import {GoogleFitConnectBeActiveTriggerIntegration} from '@cohort/merchants/apps/google-fit/triggerIntegratons/beActive/TriggerIntegration';
import {GoogleFitCompleteActivityTriggerIntegration} from '@cohort/merchants/apps/google-fit/triggerIntegratons/completeActivity/TriggerIntegration';
import type {GoogleFitAppStruct} from '@cohort/shared/apps/google-fit';
import {GoogleFitAppSpec} from '@cohort/shared/apps/google-fit';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const GoogleFitApp: App<GoogleFitAppStruct> = {
  spec: GoogleFitAppSpec,
  ConnectionEditComponent: null,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="google-fit" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [
    GoogleFitConnectBeActiveTriggerIntegration,
    GoogleFitCompleteActivityTriggerIntegration,
  ] as TriggerIntegration[],
  userEvents: [],
};

export default GoogleFitApp;
