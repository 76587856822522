/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {ConnectionADto, ListConnectionsParamsADto} from '@cohort/admin-schemas/connection';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {
  getConnectionById,
  getConnectionProfile,
  listConnections,
} from '@cohort/merchants/lib/api/Connections';
import type {ConnectorStruct, ExternalUserProfile} from '@cohort/shared/apps/app';
import type {UseQueryResult} from '@tanstack/react-query';

export const connectionsKeys = {
  connections: ['connections'] as const,
  list: (merchantId: string) => [...connectionsKeys.connections, merchantId, 'list'] as const,
  listWithFilters: (merchantId: string, params?: ListConnectionsParamsADto) =>
    [...connectionsKeys.list(merchantId), params] as const,
  getById: (merchantId: string, connectionId: string) =>
    [...connectionsKeys.connections, merchantId, connectionId] as const,
  getProfile: (merchantId: string, connectionId: string) =>
    [...connectionsKeys.getById(merchantId, connectionId), 'profile'] as const,
};

export const useConnection = (merchantId: string, connectionId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: connectionsKeys.getById(merchantId, connectionId),
    queryFn: async () => getConnectionById(merchantId, connectionId),
    ...options,
  });

// Make this function parameterized
export function useConnectionProfile<Connector extends ConnectorStruct>(
  merchantId: string,
  connectionId: string,
  options?: QueryOptions
): UseQueryResult<ExternalUserProfile<Connector>> {
  return useCohortQuery({
    queryKey: connectionsKeys.getProfile(merchantId, connectionId),
    queryFn: async () => getConnectionProfile<Connector>(merchantId, connectionId),
    ...options,
  });
}

export const useConnections = (
  merchantId: string,
  params?: ListConnectionsParamsADto,
  enabled?: boolean
): UseQueryResult<ConnectionADto[]> =>
  useCohortQuery({
    queryKey: connectionsKeys.listWithFilters(merchantId, params),
    queryFn: async () => {
      return listConnections(merchantId, params);
    },
    enabled,
  });
