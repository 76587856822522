import {
  SelectMediaConfig,
  SelectMediaConfigButton,
  SelectMediaConfigEmptyState,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaConfig';
import {SelectMediaContextProvider} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaContextProvider';
import InstagramMediaCard from '@cohort/merchants/apps/instagram/triggerIntegrations/components/InstagramMediaCard';
import SelectPostSheet from '@cohort/merchants/apps/instagram/triggerIntegrations/components/SelectPostSheet';
import type {StepTriggerFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import type {InstagramMedia} from '@cohort/shared/apps/instagram/common';
import {Fragment} from 'react/jsx-runtime';
import type {UseFormSetValue} from 'react-hook-form';

type InstagramMediaSelectorProps = {
  description: string;
  setValue: UseFormSetValue<StepTriggerFormType>;
  title: string;
  medias: InstagramMedia[];
  selectedMedia?: InstagramMedia;
};

const InstagramMediaSelector: React.FC<InstagramMediaSelectorProps> = ({
  description,
  setValue,
  selectedMedia,
  title,
  medias,
}) => (
  <SelectMediaContextProvider defaultMediaId={selectedMedia?.id ?? null}>
    <SelectMediaConfig title={title} description={description}>
      {selectedMedia === undefined ? (
        <Fragment>
          <SelectMediaConfigEmptyState />
          <SelectMediaConfigButton mode="add" />
        </Fragment>
      ) : (
        <Fragment>
          <InstagramMediaCard media={selectedMedia} />
          <SelectMediaConfigButton mode="change" />
        </Fragment>
      )}
      <SelectPostSheet setValue={setValue} medias={medias} />
    </SelectMediaConfig>
  </SelectMediaContextProvider>
);

export default InstagramMediaSelector;
