import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {urlTransformer, urlValidator} from '@cohort/shared/utils/validators';
import {z} from 'zod';

const CohortVisitLinkTriggerConfigSchema = z.object({
  url: z
    .string()
    .refine(urlValidator, 'errorUrl')
    .transform(url => urlTransformer(url)),
});
type CohortVisitLinkTriggerConfig = z.infer<typeof CohortVisitLinkTriggerConfigSchema>;

const CohortVisitLinkVerificationAttemptDataSchema = z.object({
  url: z
    .string()
    .refine(urlValidator, 'errorUrl')
    .transform(url => urlTransformer(url)),
});

export type CohortVisitLinkVerificationAttemptData = z.infer<
  typeof CohortVisitLinkVerificationAttemptDataSchema
>;

export type CohortVisitLinkTriggerStruct = TriggerIntegrationStruct<
  'cohort.visit-link',
  CohortVisitLinkTriggerConfig,
  CohortVisitLinkVerificationAttemptData
>;

export const CohortVisitLinkTriggerIntegrationSpec: TriggerIntegrationSpec<CohortVisitLinkTriggerStruct> =
  {
    id: 'cohort.visit-link' as const,
    configurableMaxAttempts: false,
    configSchema: CohortVisitLinkTriggerConfigSchema,
    verificationAttemptDataSchema: CohortVisitLinkVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
  };
