import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const TikTokKeywordInDescriptionTriggerConfigSchema = z.object({
  keyword: z.string(),
});
type TikTokKeywordInDescriptionTriggerConfig = z.infer<
  typeof TikTokKeywordInDescriptionTriggerConfigSchema
>;

const TikTokKeywordInDescriptionVerificationAttemptDataSchema = z.object({
  keyword: z.string(),
  username: z.string(),
  shareUrl: z.string().optional(),
  embedLink: z.string().optional(),
});
export type TikTokKeywordInDescriptionVerificationAttemptData = z.infer<
  typeof TikTokKeywordInDescriptionVerificationAttemptDataSchema
>;

export type TikTokKeywordInDescriptionTriggerStruct = TriggerIntegrationStruct<
  'tiktok.keyword-in-description',
  TikTokKeywordInDescriptionTriggerConfig,
  TikTokKeywordInDescriptionVerificationAttemptData
>;
export const TikTokKeywordInDescriptionTriggerSpec: TriggerIntegrationSpec<TikTokKeywordInDescriptionTriggerStruct> =
  {
    id: 'tiktok.keyword-in-description' as const,
    configurableMaxAttempts: false,
    configSchema: TikTokKeywordInDescriptionTriggerConfigSchema,
    verificationAttemptDataSchema: TikTokKeywordInDescriptionVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
  };
