import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const SpotifyFollowUserTriggerConfigSchema = z.object({
  profileUrl: z.string().url(),
});
export type SpotifyFollowUserTriggerConfig = z.infer<typeof SpotifyFollowUserTriggerConfigSchema>;

export const SpotifyFollowUserVerificationAttemptDataSchema = z.object({
  userId: z.string(),
  userName: z.string(),
  userUrl: z.string().url(),
  follows: z.boolean(),
});
export type SpotifyFollowUserVerificationAttemptData = z.infer<
  typeof SpotifyFollowUserVerificationAttemptDataSchema
>;

export type SpotifyFollowUserTriggerStruct = TriggerIntegrationStruct<
  'spotify.follow-user',
  SpotifyFollowUserTriggerConfig,
  SpotifyFollowUserVerificationAttemptData
>;
export const SpotifyFollowUserTriggerSpec: TriggerIntegrationSpec<SpotifyFollowUserTriggerStruct> =
  {
    id: 'spotify.follow-user' as const,
    configurableMaxAttempts: false,
    configSchema: SpotifyFollowUserTriggerConfigSchema,
    verificationAttemptDataSchema: SpotifyFollowUserVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
