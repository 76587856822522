import type {ActionSpec, AppSpec, AppStruct} from '@cohort/shared/apps/app';
import {ListPostsActionSpec} from '@cohort/shared/apps/facebook/actions/listPosts';
import type {FacebookMerchantConnectorStruct} from '@cohort/shared/apps/facebook/merchantConnector';
import {FacebookMerchantConnectorSpec} from '@cohort/shared/apps/facebook/merchantConnector';
import {FacebookCommentPostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/commentPost';
import {FacebookLikePageTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePage';
import {FacebookLikePageUnverifiedTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePageUnverified';
import {FacebookLikePostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePost';
import {FacebookTagInPostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/tagInPost';
import {FacebookTagInPostUnverifiedTriggerSpec} from '@cohort/shared/apps/facebook/triggers/tagInPostUnverified';
import type {FacebookUserConnectorStruct} from '@cohort/shared/apps/facebook/userConnector';
import {FacebookUserConnectorSpec} from '@cohort/shared/apps/facebook/userConnector';
import {FacebookHasLikedPageUserPropertySpec} from '@cohort/shared/apps/facebook/userProperties/hasLikedPage';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {
  createAppUsernameUserPropertySpec,
  createBaseAppUserPropertySpec,
} from '@cohort/shared/apps/userProperty';

export const FACEBOOK_APP_ID = 'facebook' as const;
export const FACEBOOK_APP_NAME = 'Facebook' as const;

export const FacebookUserPropertiesSpecs = [
  ...createBaseAppUserPropertySpec(FACEBOOK_APP_ID, FACEBOOK_APP_NAME),
  createAppUsernameUserPropertySpec(FACEBOOK_APP_ID, FACEBOOK_APP_NAME),
  FacebookHasLikedPageUserPropertySpec,
  {
    id: 'facebook.first-name',
    dataType: 'string',
    name: 'Facebook First Name',
    scope: 'profile',
    profileKey: 'firstName',
  },
  {
    id: 'facebook.last-name',
    dataType: 'string',
    name: 'Facebook Last Name',
    scope: 'profile',
    profileKey: 'lastName',
  },
] as const satisfies ReadonlyArray<UserPropertySpec<UserPropertyStruct>>;
type FacebookUserPropertyIds = (typeof FacebookUserPropertiesSpecs)[number]['id'];

export const FacebookActionSpecs = [
  ListPostsActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type FacebookActionIds = (typeof FacebookActionSpecs)[number]['id'];

export const FacebookTriggerIntegrationSpecs = [
  FacebookLikePageTriggerSpec,
  FacebookLikePageUnverifiedTriggerSpec,
  FacebookTagInPostTriggerSpec,
  FacebookTagInPostUnverifiedTriggerSpec,
  FacebookCommentPostTriggerSpec,
  FacebookLikePostTriggerSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;
type FacebookTriggerIntegrationIds = (typeof FacebookTriggerIntegrationSpecs)[number]['id'];

export type FacebookAppStruct = AppStruct<
  'facebook',
  FacebookMerchantConnectorStruct,
  FacebookUserConnectorStruct,
  null,
  FacebookActionIds,
  never,
  never,
  FacebookTriggerIntegrationIds,
  never,
  FacebookUserPropertyIds,
  never,
  never
>;

export const FacebookAppSpec: AppSpec<FacebookAppStruct> = {
  id: FACEBOOK_APP_ID,
  name: FACEBOOK_APP_NAME,
  category: 'social-media',
  merchantConnector: FacebookMerchantConnectorSpec,
  userConnector: FacebookUserConnectorSpec,
  syncSpec: null,
  actionSpecs: FacebookActionSpecs,
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: FacebookTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: FacebookUserPropertiesSpecs,
  resourceSpecs: [],
  mediaSpecs: [],
};
