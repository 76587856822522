import {InstagramCommentSchema} from '@cohort/shared/apps/instagram/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const InstagramCommentMediaTriggerConfigSchema = z.object({
  mediaId: z.string(),
  mediaPermalink: z.string().url(),
});

export type InstagramCommentMediaTriggerConfig = z.infer<
  typeof InstagramCommentMediaTriggerConfigSchema
>;

export const InstagramCommentMediaVerificationAttemptDataSchema = z.object({
  comment: InstagramCommentSchema.extend({
    permalink: z.string().url(),
  }).nullable(),
  username: z.string().nullable(),
});
export type InstagramCommentMediaVerificationAttemptData = z.infer<
  typeof InstagramCommentMediaVerificationAttemptDataSchema
>;

export type InstagramCommentMediaTriggerStruct = TriggerIntegrationStruct<
  'instagram.comment-media',
  InstagramCommentMediaTriggerConfig,
  InstagramCommentMediaVerificationAttemptData
>;
export const InstagramCommentMediaTriggerIntegrationSpec: TriggerIntegrationSpec<InstagramCommentMediaTriggerStruct> =
  {
    id: 'instagram.comment-media' as const,
    configurableMaxAttempts: false,
    configSchema: InstagramCommentMediaTriggerConfigSchema,
    verificationAttemptDataSchema: InstagramCommentMediaVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
