// i18nOwl-ignore [app-garmin-connect.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import {GarminConnectActivityCreatedUserEvent} from '@cohort/merchants/apps/garmin-connect/activityCreated/UserEvent';
import translationEn from '@cohort/merchants/apps/garmin-connect/locales/en.json';
import translationFr from '@cohort/merchants/apps/garmin-connect/locales/fr.json';
import {GarminConnectConnectBeActiveTriggerIntegration} from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/beActive/TriggerIntegration';
import {GarminConnectCompleteActivityTriggerIntegration} from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/completeActivity/TriggerIntegration';
import type {GarminConnectAppStruct} from '@cohort/shared/apps/garmin-connect';
import {GarminConnectAppSpec} from '@cohort/shared/apps/garmin-connect';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

// i18nOwl-ignore [app-garmin-connect.userEvents.activity.created.title]

const GarminConnectApp: App<GarminConnectAppStruct> = {
  spec: GarminConnectAppSpec,
  ConnectionEditComponent: null,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="garmin-connect" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [
    GarminConnectConnectBeActiveTriggerIntegration,
    GarminConnectCompleteActivityTriggerIntegration,
  ] as TriggerIntegration[],
  userEvents: [GarminConnectActivityCreatedUserEvent],
};

export default GarminConnectApp;
