// i18nOwl-ignore [app-facebook.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import {makeOauthEditConnectionComponent} from '@cohort/merchants/apps/appUtils';
import {SetupConnectionAccount} from '@cohort/merchants/apps/facebook/connector/SetupConnectionAccount';
import translationEn from '@cohort/merchants/apps/facebook/locales/en.json';
import translationFr from '@cohort/merchants/apps/facebook/locales/fr.json';
import {FacebookCommentPostTriggerIntegration} from '@cohort/merchants/apps/facebook/triggers/commentPost/TriggerIntegration';
import {FacebookLikePageTriggerIntegration} from '@cohort/merchants/apps/facebook/triggers/likePage/TriggerIntegration';
import {FacebookLikePageUnverifiedTriggerIntegration} from '@cohort/merchants/apps/facebook/triggers/likePageUnverified/TriggerIntegration';
import {FacebookLikePostTriggerIntegration} from '@cohort/merchants/apps/facebook/triggers/likePost/TriggerIntegration';
import {FacebookTagInPostTriggerIntegration} from '@cohort/merchants/apps/facebook/triggers/tagInPost/TriggerIntegration';
import {FacebookTagInPostUnverifiedTriggerIntegration} from '@cohort/merchants/apps/facebook/triggers/tagInPostUnverified/TriggerIntegration';
import type {FacebookAppStruct} from '@cohort/shared/apps/facebook';
import {FacebookAppSpec} from '@cohort/shared/apps/facebook';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const FacebookApp: App<FacebookAppStruct> = {
  spec: FacebookAppSpec,
  ConnectionEditComponent: makeOauthEditConnectionComponent('facebook', {
    postConnectionConfigComponent: SetupConnectionAccount,
  }),
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="facebook" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [
    FacebookLikePageTriggerIntegration,
    FacebookLikePageUnverifiedTriggerIntegration,
    FacebookTagInPostTriggerIntegration,
    FacebookTagInPostUnverifiedTriggerIntegration,
    FacebookCommentPostTriggerIntegration,
    FacebookLikePostTriggerIntegration,
  ] as TriggerIntegration[],
  userEvents: [],
};

export default FacebookApp;
