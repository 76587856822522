import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortOrderCreatedPropertiesSchema = z.object({
  orderId: z.string().uuid(),
  campaignId: z.string().uuid(),
  campaignInternalName: z.string(),
  orderType: z.string(),
  amount: z.number(),
  currency: z.string().nullable(),
});
export type CohortOrderCreatedProperties = z.infer<typeof CohortOrderCreatedPropertiesSchema>;

export type CohortOrderCreatedEventStruct = UserEventStruct<
  'cohort.order.created',
  keyof CohortOrderCreatedProperties,
  CohortOrderCreatedProperties,
  CohortOrderCreatedProperties
>;

export const CohortOrderCreatedEventSpec: UserEventSpec<CohortOrderCreatedEventStruct> = {
  id: 'cohort.order.created',
  name: 'Created an Order',
  description: 'Event Triggerred when a user successfully creates an order',
  propertiesSchema: CohortOrderCreatedPropertiesSchema,
  exposedPropertiesSchema: CohortOrderCreatedPropertiesSchema,
  resources: {},
  rulesEngineConfig: {
    isVisible: false,
    exposedFields: [],
  },
};
