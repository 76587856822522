import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const YoutubeCommentTriggerConfigSchema = z.object({
  videoId: z.string(),
  videoTitle: z.string(),
});
export type YoutubeCommentTriggerConfig = z.infer<typeof YoutubeCommentTriggerConfigSchema>;

export const YoutubeCommentVerificationAttemptDataSchema = z.object({
  channelId: z.string().nullable(),
  channelTitle: z.string().nullable(),
  channelHandle: z.string().nullable(),
  comment: z
    .object({
      id: z.string(),
      videoId: z.string(),
      videoTitle: z.string(),
      text: z.string(),
      createdAt: z.date(),
    })
    .nullable(),
});
export type YoutubeCommentVerificationAttemptData = z.infer<
  typeof YoutubeCommentVerificationAttemptDataSchema
>;

export type YoutubeCommentTriggerStruct = TriggerIntegrationStruct<
  'youtube.comment',
  YoutubeCommentTriggerConfig,
  YoutubeCommentVerificationAttemptData
>;
export const YoutubeCommentTriggerSpec: TriggerIntegrationSpec<YoutubeCommentTriggerStruct> = {
  id: 'youtube.comment' as const,
  configurableMaxAttempts: false,
  configSchema: YoutubeCommentTriggerConfigSchema,
  verificationAttemptDataSchema: YoutubeCommentVerificationAttemptDataSchema,
  verificationAttemptInputSchema: z.object({}),
  requiresMerchantConnection: true,
};
