import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import NetworkedSelect from '@cohort/merchants/components/form/select/NetworkedSelect';
import {useCohorts} from '@cohort/merchants/hooks/api/Cohorts';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useState} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const PAGE_SIZE = 50;

const CohortBeInCohortTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn: {control, register, watch}}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.be-in-cohort.configComponent',
  });
  const {field: cohortId, fieldState: cohortIdState} = useController({
    control,
    name: 'triggerIntegrationConfig.cohortId',
  });

  const [search, setSearch] = useState<string | undefined>(undefined);
  const {data: cohortsData, isLoading} = useCohorts(merchant.id, {
    pageSize: PAGE_SIZE,
    page: 1,
    ...(search !== undefined && search.trim().length > 0 && {search}),
  });
  const [, cohorts] = cohortsData ?? [];

  const cohortsOptions =
    cohorts?.map(cohort => ({
      value: cohort.id,
      label: cohort.name,
    })) ?? [];
  const selectedCohort = cohorts?.find(prop => prop.id === cohortId.value);

  const selectedLanguage = watch('selectedLanguage');

  return (
    <div className="space-y-4">
      <NetworkedSelect
        label={t('labelCohort')}
        name="triggerIntegrationConfig.cohortId"
        error={cohortIdState.error?.message}
        placeholder={t('selectCohort')}
        options={cohortsOptions}
        onInputChange={setSearch}
        isLoading={isLoading}
        onChange={val => cohortId.onChange(val?.value)}
        value={
          selectedCohort
            ? {
                value: selectedCohort.id,
                label: selectedCohort.name,
              }
            : null
        }
      />
      <LocalizedInput
        type="text"
        label={t('labelTitleInput')}
        name="triggerIntegrationConfig.title"
        register={register}
        control={control}
        selectedLanguage={selectedLanguage}
      />
    </div>
  );
};

export default CohortBeInCohortTriggerIntegrationConfigComponent;
