import type {ActionSpec, AppSpec, AppStruct} from '@cohort/shared/apps/app';
import {ListVideosActionSpec} from '@cohort/shared/apps/tiktok/actions/listVideos';
import type {TikTokMerchantConnectorStruct} from '@cohort/shared/apps/tiktok/merchantConnector';
import {TikTokMerchantConnectorSpec} from '@cohort/shared/apps/tiktok/merchantConnector';
import {TikTokCommentTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/commentTiktok';
import {TikTokFollowTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/follow';
import {TikTokKeywordInBioTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import {TikTokKeywordInDescriptionTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import {TikTokLikeTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';
import {TikTokRepostTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/repostTiktok';
import type {TikTokConnectorStruct} from '@cohort/shared/apps/tiktok/userConnector';
import {TikTokConnectorSpec} from '@cohort/shared/apps/tiktok/userConnector';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {
  createAppUsernameUserPropertySpec,
  createBaseAppUserPropertySpec,
} from '@cohort/shared/apps/userProperty';

export const TIKTOK_APP_ID = 'tiktok' as const;
export const TIKTOK_APP_NAME = 'TikTok' as const;

export const TikTokUserPropertiesSpecs = [
  ...createBaseAppUserPropertySpec(TIKTOK_APP_ID, TIKTOK_APP_NAME),
  createAppUsernameUserPropertySpec(TIKTOK_APP_ID, TIKTOK_APP_NAME),
  {
    id: 'tiktok.followers-count',
    dataType: 'number',
    name: 'TikTok Number of Followers',
    scope: 'profile',
    profileKey: 'followersCount',
  },
  {
    id: 'tiktok.following-count',
    dataType: 'number',
    name: 'TikTok Number of Following',
    scope: 'profile',
    profileKey: 'followingCount',
  },
  {
    id: 'tiktok.likes-count',
    dataType: 'number',
    name: 'TikTok Number of Likes',
    scope: 'profile',
    profileKey: 'likesCount',
  },
  {
    id: 'tiktok.videos-count',
    dataType: 'number',
    name: 'TikTok Number of Videos',
    scope: 'profile',
    profileKey: 'videosCount',
  },
] as const satisfies ReadonlyArray<UserPropertySpec<UserPropertyStruct>>;
type TikTokUserPropertyIds = (typeof TikTokUserPropertiesSpecs)[number]['id'];

export const TikTokActionSpecs = [
  ListVideosActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type TikTokActionIds = (typeof TikTokActionSpecs)[number]['id'];

export const TikTokTriggerIntegrationSpecs = [
  TikTokFollowTriggerSpec,
  TikTokLikeTiktokTriggerSpec,
  TikTokCommentTiktokTriggerSpec,
  TikTokRepostTiktokTriggerSpec,
  TikTokKeywordInBioTriggerSpec,
  TikTokKeywordInDescriptionTriggerSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;
type TikTokTriggerIntegrationIds = (typeof TikTokTriggerIntegrationSpecs)[number]['id'];

export type TikTokAppStruct = AppStruct<
  'tiktok',
  TikTokMerchantConnectorStruct,
  TikTokConnectorStruct,
  null,
  TikTokActionIds,
  never,
  never,
  TikTokTriggerIntegrationIds,
  never,
  TikTokUserPropertyIds,
  never,
  never
>;

export const TikTokAppSpec: AppSpec<TikTokAppStruct> = {
  id: TIKTOK_APP_ID,
  name: TIKTOK_APP_NAME,
  category: 'social-media',
  merchantConnector: TikTokMerchantConnectorSpec,
  userConnector: TikTokConnectorSpec,
  syncSpec: null,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: TikTokTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: TikTokUserPropertiesSpecs,
  resourceSpecs: [],
  mediaSpecs: [],
};
