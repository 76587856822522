import {z} from 'zod';

export const CohortErrorCodeSchema = z.enum([
  'app.comarch.invalid-api-url',
  'app.comarch.ressource-not-found',
  'app.discord.guild-not-found',
  'app.discord.member-not-found',
  'app.discord.no-system-channel',
  'app.discord.role-not-found',
  'app.google-fit.default-stream-not-available',
  'app.instagram.instagram-account-not-found',
  'app.instagram.media-not-found',
  'app.salesforce.invalid-query',
  'app.shopify.bulk-operation-mutation-error',
  'app.shopify.staged-upload-error',
  'app.strava.activity-not-found',
  'app.talon-one.resource-already-exists',
  'app.twitter.insufficient-app-permissions',
  'app.youtube.no-user-channels',
  'app.youtube.playlist-not-found',
  'app.youtube.video-not-found',
  'app.facebook.page-not-found',
  'app.facebook.resource-not-found',
  'app-framework.execute-action-error',
  'app-framework.send-notification-error',
  'app-framework.validate-notification-integration-config-error',
  'app-framework.activate-perk-access-error',
  'app-framework.create-perk-instance-error',
  'app-framework.delete-perk-access-error',
  'app-framework.delete-perk-instance-error',
  'app-framework.export-user-events-error',
  'app-framework.export-users-error',
  'app-framework.sample-perk-usage-data-error',
  'app-framework.transform-perk-usage-data-error',
  'app-framework.update-perk-instance-config-error',
  'app-framework.use-perk-access-error',
  'app-framework.validate-perk-integration-config-error',
  'app-framework.get-resource-error',
  'app-framework.list-resource-error',
  'app-framework.get-trigger-default-title-error',
  'app-framework.get-trigger-default-description-error',
  'app-framework.validate-trigger-config-error',
  'app-framework.verify-trigger-completion-error',
  'app-framework.refresh-user-property-values-error',
  'app-framework.create-user-sync-error',
  'app-framework.import-contents-user-sync-error',
  'app-framework.import-users-user-sync-error',
  'app-framework.get-user-profile-error',
  'app-framework.handle-webhook-error',
  'digital-asset-collection.max-ownerships-reached',
  'digital-asset-collection.not-found',
  'digital-asset-collection.smart-contract-already-set',
  'digital-asset.already-minted',
  'digital-asset.blockchain-tx-request-already-set',
  'digital-asset.invalid-input-data',
  'digital-asset.nft-already-set',
  'digital-asset.not-found',
  'digital-asset.not-minted',
  'digital-asset.token-already-set',
  'digital-asset.token-not-available',
  'membership-pass-configuration.smart-contract-already-set',
  'membership-pass.invalid-member-handle',
  'membership-pass.duplicated-member-handle',
  'membership-pass.nft-already-set',
  'nft.mint-transaction-already-set',
  'nft.mint-transaction-request-already-set',
  'nft.not-found',
  'nft.not-minted',
  'notification-integration.invalid-config',
  'notification-integration.not-found',
  'notification-integration.request-failed',
  'notification-configuration.user-event-not-supported',
  'perk-integration.disabled',
  'perk-integration.invalid-access-data',
  'perk-integration.invalid-config',
  'perk-integration.invalid-exposed-usage-data',
  'perk-integration.invalid-internal-usage-data',
  'perk-integration.invalid-state',
  'perk-integration.invalid-usage-input-data',
  'perk-integration.not-found',
  'reward-box.invalid-configuration',
  'reward-configuration.cannot-be-unlocked',
  'reward-configuration.invalid-configuration',
  'reward-configuration.not-found',
  'rule.criterion-filter-not-found',
  'rule.criterion-not-found',
  'rule.criterion-type-mismatch',
  'rule.group-not-found',
  'rule.invalid-criterion-data',
  'rule.not-found',
  'rule.not-ready',
  'rule.operator-not-found',
  'rule.transformer-not-foud',
  'smart-contract.deploy-transaction-already-set',
  'smart-contract.deploy-transaction-request-already-set',
  'smart-contract.not-deployed',
  'smart-contract.not-found',
  'trigger-integration.completion-failed',
  'trigger-integration.disabled',
  'trigger-integration.invalid-config',
  'trigger-integration.invalid-verification-attempt-data',
  'trigger-integration.not-found',
  'alchemy.invalid-webhook',
  'airdrop.in-progress',
  'airdrop.invalid-input',
  'analytics.security-violation',
  'api-key.not-found',
  'app.invalid-configuration',
  'app.not-found',
  'app.rate-limit-exceeded',
  'app.retryable-error',
  'app.unretryable-error',
  'app-action.disabled',
  'app-action.invalid-connection',
  'app-action.invalid-input',
  'app-action.invalid-output',
  'app-action.failed',
  'app-action.not-found',
  'app-action.unauthorized',
  'asset.invalid-content-type',
  'auth.id-token-expired',
  'auth.invalid-auth-token',
  'auth.invalid-otp-code',
  'auth.invalid-turnstile-token',
  'auth.invalid-user',
  'auth.unauthorized',
  'auth.insufficient-permissions',
  'badge.already-published',
  'badge.invalid-image-file-key',
  'badge.not-found',
  'badge.not-publishable',
  'cohort.invalid-configuration',
  'cohort.invalid-type',
  'cohort.used-in-badge',
  'cohort.used-in-campaign',
  'cohort.used-in-rule',
  'cohort.not-found',
  'cohort.user-not-member',
  'cohort.reference-id-already-exists',
  'connection.already-configured',
  'connection.broken',
  'connection.in-use',
  'connection.invalid-config',
  'connection.invalid-type',
  'connection.not-broken',
  'connection.not-found',
  'connection.not-ready',
  'connector.invalid-type',
  'connector.credentials-invalid',
  'connector.external-service-error',
  'connector.installation-error',
  'connector.insufficient-permissions',
  'connector.network-error',
  'connector.not-found',
  'connector.webhook-processing-error-unretryable',
  'connector.webhook-processing-error',
  'connector.webhook-validation-error',
  'blockchain.invalid-network',
  'blockchain.missing-contract-address',
  'blockchain-tx-request.invalid-gas-limit',
  'blockchain-tx-request.invalid-status',
  'blockchain-tx-request.mined-tx-not-found',
  'blockchain-tx-request.not-found',
  'campaign.already-exists',
  'campaign.already-published',
  'campaign.ended',
  'campaign.invalid-audience',
  'campaign.invalid-configuration',
  'campaign.invalid-currency',
  'campaign.invalid-pricing',
  'campaign.invalid-type',
  'campaign.max-orders-reached',
  'campaign.not-enough-tokens',
  'campaign.not-found',
  'campaign.not-archived',
  'campaign.not-publishable',
  'campaign.not-published',
  'campaign.order-session-expired',
  'campaign.redeem-code-missing',
  'challenge.not-found',
  'challenge.cohort-not-found',
  'challenge-participation.already-completed',
  'challenge-participation.not-completed',
  'challenge-participation.not-found',
  'challenge-step-completion.already-completed',
  'challenge-step-completion.attempt-not-found',
  'challenge-step-completion.max-attempts-reached',
  'challenge-step-completion.verification-failed',
  'challenge-step-trigger.not-found',
  'challenge-step.invalid-configuration',
  'challenge-step.locked',
  'challenge-step.not-found',
  'challenge-steps.invalid-configuration',
  'collection.not-found',
  'content.already-published',
  'content.not-found',
  'content-category.title-not-set',
  'content-category.not-found',
  'content.not-publishable',
  'country-spec.not-found',
  'currency-spec.not-found',
  'generic.invalid-request',
  'generic.translation-not-found',
  'generic.unknown-error',
  'healthcheck.bottleneck-error',
  'healthcheck.postgres-error',
  'healthcheck.redis-error',
  'infra.pubsub-error',
  'infra.redlock-error',
  'instagram.member-handle-not-found',
  'media.asset-does-not-exist',
  'media.invalid-mime-type',
  'merchant.custom-font-not-found',
  'merchant.invalid-configuration',
  'merchant.invalid-custom-stylesheet',
  'merchant.invalid-font-stylesheet-url',
  'merchant.missing-style-configuration',
  'merchant.invalid-style-value',
  'merchant.not-found',
  'merchant.not-publishable',
  'merchant.svix-integration-not-set',
  'notification-configuration.not-found',
  'oauth.access-denied',
  'oauth.authorization-code-invalid',
  'oauth.authorization-error-retryable',
  'oauth.authorization-error-unretryable',
  'order.already-minted',
  'order.already-processed',
  'order.duplicate-user',
  'order.not-found',
  'order.process-error',
  'organization-member.already-active',
  'organization-member.already-exists',
  'organization-member.not-active',
  'organization-member.not-found',
  'organization.invalid-slug',
  'organization.is-live',
  'organization.not-found',
  'ownership.campaign-or-digital-asset-collection-not-found',
  'ownership.invalid-status',
  'ownership.not-found',
  'ownership.transfer-job-not-found',
  'payment-session.not-found',
  'perk.already-published',
  'perk.invalid-configuration',
  'perk.invalid-type',
  'perk.invalid-max-usages-per-token',
  'perk.invalid-usage-data',
  'perk.is-archived',
  'perk.not-found',
  'perk.not-published',
  'perk.usages-limit-reached',
  'perk-access.not-found',
  'perk-usage.not-found',
  'qr-code.expired',
  'qr-code.invalid-code',
  'qr-code.invalid-ownership',
  'qr-code.invalid-perk-type',
  'qr-code.invalid-reader',
  'qr-code.token-already-used',
  'qr-reader.not-found',
  'redeem-code.code-invalid',
  'redeem-code.not-found',
  'resource.invalid-output',
  'resource.invalid-resource-type',
  'resource.not-found',
  'reward-configuration.depleted',
  'shopify.app-name-already-exists',
  'shopify.app-not-found',
  'shopify.shop-not-found',
  'store.not-found',
  'stripe.connect-account-already-exists',
  'stripe.connect-account-already-live',
  'stripe.connect-account-country-not-available',
  'stripe.connect-account-does-not-exist',
  'stripe.connect-account-not-found',
  'stripe.connect-account-not-live',
  'sync.already-exists',
  'sync.disabled',
  'sync.not-found',
  'sync.invalid-status',
  'sync-integration.not-found',
  'team.invitation.code-not-found',
  'team.invitation.expired',
  'tiktok.member-handle-not-found',
  'transfer-invitation.not-active',
  'transfer-invitation.not-found',
  'transfer.already-transferred',
  'transfer.invalid-recipient',
  'transfer.invalid-validation-code',
  'transfer.not-allowed',
  'transfer.not-found',
  'twitter.member-handle-not-found',
  'user.invalid-auth-token-creation-data',
  'user.invalid-language',
  'user.invalid-subscription-date',
  'user.not-found',
  'user.wallet-already-set',
  'user-attribute.invalid-value',
  'user-event.invalid-event-type',
  'user-event.not-found',
  'user-property.invalid-app-user-property-type',
  'user-property.not-found',
  'user-property.controlled-by-user-sync',
  'user-property.reference-id-already-exists',
  'user-property.used-in-rule',
  'wallet.not-found',
  'webhook.disabled',
]);
export type CohortErrorCode = z.infer<typeof CohortErrorCodeSchema>;

export const authErrors: Array<CohortErrorCode> = [
  'auth.unauthorized',
  'auth.id-token-expired',
  'auth.invalid-user',
];

export class CohortError extends Error {
  public code: CohortErrorCode;
  public context: Record<string, unknown>;

  public constructor(code: CohortErrorCode, context: Record<string, unknown> = {}, cause?: Error) {
    super(code, {cause});
    this.name = 'CohortError';
    this.message = code;
    this.code = code;
    this.context = context;
  }
}

export function isCohortError(
  err: unknown,
  codes?: CohortErrorCode | CohortErrorCode[]
): err is CohortError {
  return (
    err instanceof CohortError &&
    (codes === undefined || err.code === codes || codes.includes(err.code))
  );
}
