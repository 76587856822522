import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortUserPropertyConditionMetTriggerIntegrationConfigComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/userPropertyConditionMet/ConfigComponent';
import CohortUserPropertyConditionMetTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/userPropertyConditionMet/StepCompletionTitleComponent';
import type {CohortUserPropertyConditionMetTriggerStruct} from '@cohort/shared/apps/cohort/triggers/userPropertyConditionMet';
import {CohortUserPropertyConditionMetTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/userPropertyConditionMet';

export const CohortUserPropertyConditionMetTriggerIntegration: TriggerIntegration<CohortUserPropertyConditionMetTriggerStruct> =
  {
    spec: CohortUserPropertyConditionMetTriggerIntegrationSpec,
    configComponent: CohortUserPropertyConditionMetTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent:
      CohortUserPropertyConditionMetTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggerIntegrations.user-property-condition-met.title', {ns: 'app-cohort'}),
  };
