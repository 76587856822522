import type {TriggerIntegrationSpec, UnverifiedTriggerStruct} from '@cohort/shared/apps/trigger';
import {UnverifiedTriggerBaseConfigSchema} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const TikTokLikeTiktokTriggerConfigSchema = UnverifiedTriggerBaseConfigSchema.extend({
  videoId: z.string(),
});
type TikTokLikeTiktokTriggerConfig = z.infer<typeof TikTokLikeTiktokTriggerConfigSchema>;

const TikTokLikeTiktokVerificationAttemptDataSchema = z.object({
  username: z.string(),
  url: z.string().url(),
});
export type TikTokLikeTiktokVerificationAttemptData = z.infer<
  typeof TikTokLikeTiktokVerificationAttemptDataSchema
>;

export type TikTokLikeTiktokTriggerStruct = UnverifiedTriggerStruct<
  'tiktok.like-tiktok',
  TikTokLikeTiktokTriggerConfig,
  TikTokLikeTiktokVerificationAttemptData
>;
export const TikTokLikeTiktokTriggerSpec: TriggerIntegrationSpec<TikTokLikeTiktokTriggerStruct> = {
  id: 'tiktok.like-tiktok' as const,
  configurableMaxAttempts: false,
  configSchema: TikTokLikeTiktokTriggerConfigSchema,
  verificationAttemptDataSchema: TikTokLikeTiktokVerificationAttemptDataSchema,
  verificationAttemptInputSchema: z.object({}),
  requiresMerchantConnection: true,
};
