import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const CohortPerkUsedTriggerConfigSchema = z.object({
  perkId: z.string().uuid(),
});
export type CohortPerkUsedTriggerConfig = z.infer<typeof CohortPerkUsedTriggerConfigSchema>;

export const CohortPerkUsedVerificationAttemptDataSchema = z.object({
  perkUsages: z.array(
    z.object({
      usageId: z.string().uuid(),
      accessId: z.string().uuid(),
    })
  ),
});

export type CohortPerkUsedVerificationAttemptData = z.infer<
  typeof CohortPerkUsedVerificationAttemptDataSchema
>;

export type CohortPerkUsedTriggerStruct = TriggerIntegrationStruct<
  'cohort.perk-used',
  CohortPerkUsedTriggerConfig,
  CohortPerkUsedVerificationAttemptData
>;

export const CohortPerkUsedTriggerIntegrationSpec: TriggerIntegrationSpec<CohortPerkUsedTriggerStruct> =
  {
    id: 'cohort.perk-used' as const,
    configurableMaxAttempts: false,
    configSchema: CohortPerkUsedTriggerConfigSchema,
    verificationAttemptDataSchema: CohortPerkUsedVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
  };
