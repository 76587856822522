import {XCircle} from '@phosphor-icons/react';

const EmbedError: React.FC<{error: string}> = ({error}) => (
  <div className="flex items-center justify-center border bg-white p-4">
    <p className="inline-flex items-center gap-2 text-red-500">
      <XCircle size={16} />
      {error}
    </p>
  </div>
);

export default EmbedError;
