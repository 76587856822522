import {LanguageSchema, richTextSchemaTransform} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const ContentTitleSchema = z
  .record(LanguageSchema, z.string().min(1, 'errorTooShort1').nullable())
  .describe(`A mapping of locale to string. Example: {en: 'Hello', fr: 'Bonjour'}`);
export type ContentTitle = z.infer<typeof ContentTitleSchema>;

export const ContentDescriptionSchema = z
  .record(
    LanguageSchema,
    z.string().min(8, 'errorTooShort1').transform(richTextSchemaTransform).nullable()
  )
  .describe(
    `A mapping of locale to rich text. Example: {en: '<p>Hello</p>', fr: '<p>Bonjour</p>'}`
  );
export type ContentDescription = z.infer<typeof ContentDescriptionSchema>;

export const ContentStatusSchema = z.enum(['draft', 'published']);
export type ContentStatus = z.infer<typeof ContentStatusSchema>;

export const ContentAudienceSchema = z.enum(['everyone', 'cohort', 'notVisible']);
export type ContentAudience = z.infer<typeof ContentAudienceSchema>;

export const ContentsOrderBySchema = z.enum([
  'createdAt',
  '-createdAt',
  'viewsCount',
  '-viewsCount',
]);
export type ContentsOrderByParams = z.infer<typeof ContentsOrderBySchema>;
