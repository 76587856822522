import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import type {CohortUserPropertyConditionMetVerificationAttemptData} from '@cohort/shared/apps/cohort/triggers/userPropertyConditionMet';
import {useTranslation} from 'react-i18next';

const CohortUserPropertyConditionMetTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: CohortUserPropertyConditionMetVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {title} = data;
  const {i18n} = useTranslation();

  const localizedTitle =
    title[i18n.language as keyof typeof title] ?? (Object.values(title)[0] as string);

  return (
    <StepCompletionCard
      translationConfig={{
        // i18nOwl-ignore [app-cohort.triggerIntegrations.user-property-condition-met.stepCompletionTitleComponent.success]
        // i18nOwl-ignore [app-cohort.triggerIntegrations.user-property-condition-met.stepCompletionTitleComponent.error]
        key: `triggerIntegrations.user-property-condition-met.stepCompletionTitleComponent.${completionSuccess ? 'success' : 'error'}`,
        ns: 'app-cohort',
        value: localizedTitle,
      }}
    />
  );
};

export default CohortUserPropertyConditionMetTriggerIntegrationStepCompletionTitleComponent;
