import type {
  FacebookTagInPostTriggerConfig,
  FacebookTagInPostVerificationAttemptData,
} from '@cohort/shared/apps/facebook/triggers/tagInPost';
import {
  FacebookTagInPostTriggerConfigSchema,
  FacebookTagInPostVerificationAttemptDataSchema,
} from '@cohort/shared/apps/facebook/triggers/tagInPost';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export type FacebookTagInPostUnverifiedTriggerStruct = TriggerIntegrationStruct<
  'facebook.tag-in-post-unverified',
  FacebookTagInPostTriggerConfig,
  FacebookTagInPostVerificationAttemptData
>;
export const FacebookTagInPostUnverifiedTriggerSpec: TriggerIntegrationSpec<FacebookTagInPostUnverifiedTriggerStruct> =
  {
    id: 'facebook.tag-in-post-unverified' as const,
    configurableMaxAttempts: false,
    configSchema: FacebookTagInPostTriggerConfigSchema,
    verificationAttemptDataSchema: FacebookTagInPostVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
