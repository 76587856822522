import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {TypeformQuestionWithAnswerSchema} from '@cohort/shared/apps/typeform/common';
import {z} from 'zod';

const TypeformTakeQuizTriggerConfigSchema = z.object({
  formId: z.string(),
  minScore: z.number().int().min(0),
});
export type TypeformTakeQuizTriggerConfig = z.infer<typeof TypeformTakeQuizTriggerConfigSchema>;

const TypeformTakeQuizVerificationAttemptDataSchema = z.object({
  formId: z.string(),
  responseId: z.string(),
  formTitle: z.string().optional(),
  questionsWithAnswers: z.array(TypeformQuestionWithAnswerSchema),
  minScore: z.number().int().min(0).optional(),
  userScore: z.number().int().optional(), // @Devs userScore can be negative.
});
export type TypeformTakeQuizVerificationAttemptData = z.infer<
  typeof TypeformTakeQuizVerificationAttemptDataSchema
>;

const TypeformTakeQuizVerificationAttemptInputSchema = z.object({
  responseId: z.string(),
});
export type TypeformTakeQuizVerificationAttemptInput = z.infer<
  typeof TypeformTakeQuizVerificationAttemptInputSchema
>;

export type TypeformTakeQuizTriggerStruct = TriggerIntegrationStruct<
  'typeform.take-quiz',
  TypeformTakeQuizTriggerConfig,
  TypeformTakeQuizVerificationAttemptData,
  TypeformTakeQuizVerificationAttemptInput
>;

export const TypeformTakeQuizTriggerIntegrationSpec: TriggerIntegrationSpec<TypeformTakeQuizTriggerStruct> =
  {
    id: 'typeform.take-quiz' as const,
    configurableMaxAttempts: true,
    configSchema: TypeformTakeQuizTriggerConfigSchema,
    verificationAttemptDataSchema: TypeformTakeQuizVerificationAttemptDataSchema,
    verificationAttemptInputSchema: TypeformTakeQuizVerificationAttemptInputSchema,
    requiresMerchantConnection: true,
  };
