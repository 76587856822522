import type {MediaSpec, MediaStruct} from '@cohort/shared/apps/media';
import {z} from 'zod';

export const CohortImageMediaConfigSchema = z.object({
  imageFileKey: z.string({
    required_error: 'errorRequired',
    invalid_type_error: 'errorRequired',
  }),
});
export type CohortImageMediaConfig = z.infer<typeof CohortImageMediaConfigSchema>;

export type CohortImageMediaStruct = MediaStruct<'cohort.image', CohortImageMediaConfig>;

export const CohortImageMediaSpec: MediaSpec<CohortImageMediaStruct> = {
  kind: 'cohort.image' as const,
  configSchema: CohortImageMediaConfigSchema,
};
