import Button from '@cohort/merchants/components/buttons/Button';
import {notify} from '@cohort/merchants/hooks/toast';
import {Copy} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

type QRCodeUrlButtonProps = {
  campaignStandaloneUrl: string;
  stepId: string;
};

const QRCodeUrlButton: React.FC<QRCodeUrlButtonProps> = ({campaignStandaloneUrl, stepId}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'campaigns.challenges.qrCodeUrlButton',
  });

  return (
    <Button
      variant="link"
      onClick={async () => {
        await navigator.clipboard.writeText(`${campaignStandaloneUrl}/steps/${stepId}/verify`);

        notify('success', t('urlCopied'));
      }}
    >
      <Copy size={16} weight="bold" className="mr-2" />
      {t('copyQRCodeUrl')}
    </Button>
  );
};

export default QRCodeUrlButton;
